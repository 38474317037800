import React, { useContext } from "react";
import { ContactContext } from "../../contexts/ContactContext";

const ContactUs = () => {
  const {
    contacts,
    totalPages,
    currentPage,
    updateCurrentPage,
    pageSize,
    handlePageSizeChange,
    loading,
  } = useContext(ContactContext);

  const formatDate = (dateArray) => {
    if (!dateArray) return "N/A";
    const [year, month, day, hour, minute, second] = dateArray;
    return `${day}/${month}/${year}`;
    // return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  };

  return (
    <section className="contact-us-page">
      <div className="our-container px-3">
        <div className="inner-container">
          <div className="contact-us-page-heading mb-4 d-flex align-items-center justify-content-between">
            <div className="">
              <h2>Contact Us</h2>
            </div>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="contact-us-page-body table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Message</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.length > 0 ? (
                    contacts.map((contact, index) => (
                      <tr key={contact.pkUserId}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{contact.userName}</td>
                        <td>{contact.userEmail}</td>
                        <td>{contact.userPhoneNo}</td>
                        <td>{contact.userMessage}</td>
                        <td>{formatDate(contact.createdDate)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No contacts found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="pagination-controls">
                <label>
                  Show
                  <select value={pageSize} onChange={handlePageSizeChange}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                  Contacts per Page
                </label>
                <div className="pagination-buttons">
                  <button
                    className="pagination-btn"
                    onClick={() => updateCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className="pagination-btn"
                    onClick={() => updateCurrentPage(currentPage + 1)}
                    disabled={currentPage >= totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
