import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { AuthContext } from "../../contexts/AuthContext";
import { HiMenuAlt1 } from "react-icons/hi";
import { ContactContext } from "../../contexts/ContactContext";

const Header = () => {
  const { signOut, user } = useContext(AuthContext);
  const { fetchContactData } = useContext(ContactContext);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeOffcanvas = () => {
    const closeButton = document.querySelector('[data-bs-dismiss="offcanvas"]');
    if (closeButton) {
      closeButton.click();
    }
  };

  const contactDataHandler = () => {
    // fetchContactData();
  };

  return (
    <>
      {/* <header className={isFixed ? "fixed-header" : ""}> */}
      <header>
        <div className="py-1 header-top"></div>
        <div className="upper-menu-block">
          <div className="our-container">
            <div className="inner-container d-flex align-items-center justify-content-between mx-auto px-3">
              <div className="logo-block my-2">
                <Link to={"/"} className="d-block">
                  <img src="/images/karv-logo.png" alt="" />
                </Link>
              </div>
              <div className="mobile-info-menu">
                <div className="user-name-show-mobile">
                  <FaUserCircle className="fa-user-icon fs-4" />
                  <p className="mb-0 fw-semibold">{user?.userName}</p>
                </div>
                <button
                  className="btn menu-icon-button"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasHeader"
                  aria-controls="offcanvasHeader"
                >
                  <HiMenuAlt1 className="menu-icon" />
                </button>
              </div>
              <div className="main-menu">
                <nav>
                  <ul className="p-0 d-flex align-items-center justify-content-center mb-0 nav-links">
                    <li>
                      <NavLink to={"/"} className="fs-6 fw-semibold nav-items">
                        Dashboard
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/product"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Product
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/b2b"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        B2B
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/product-category"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Product Category
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/product-type"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Product Type
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/orders"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Orders
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/paid-orders"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Paid Orders
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/contact-us"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => {
                          fetchContactData();
                        }}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li>
                      <div className="dropdown">
                        <button
                          className=" btn-secondary dropdown-toggle nav-link text-black fs-6 fw-semibold nav-items d-flex align-items-center gap-2 myBTN"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FaUserCircle className="fa-user-icon fs-4" />
                          <p className="mb-0">{user?.userName}</p>
                        </button>
                        <ul className="dropdown-menu nav-links">
                          <li className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn nav-link text-black fs-6 fw-semibold nav-items d-flex align-items-center gap-2"
                              type="button"
                              onClick={() => {
                                signOut();
                              }}
                            >
                              <FiLogOut className="fa-user-icon" />
                              <p className="mb-0"> Sign Out</p>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasHeader"
        aria-labelledby="offcanvasHeaderLabel"
      >
        <div className="offcanvas-header mobile-header-offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasHeaderLabel">
            <Link to={"/"} className="d-block" onClick={closeOffcanvas}>
              <img src="/images/karv-logo.png" alt="" width={100} />
            </Link>
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body mobile-header-offcanvas-body">
          <div className="mobile-menu-nav">
            <ul>
              <li>
                <NavLink
                  to={"/"}
                  className="fs-6 fw-semibold nav-items"
                  onClick={closeOffcanvas}
                >
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/product"}
                  className="fs-6 fw-semibold nav-items"
                  onClick={closeOffcanvas}
                >
                  Product
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/b2b"}
                  className="fs-6 fw-semibold nav-items"
                  onClick={closeOffcanvas}
                >
                  B2B
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/product-category"}
                  className="fs-6 fw-semibold nav-items"
                  onClick={closeOffcanvas}
                >
                  Product Category
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/product-type"}
                  className="fs-6 fw-semibold nav-items"
                  onClick={closeOffcanvas}
                >
                  Product Type
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/orders"}
                  className="fs-6 fw-semibold nav-items"
                  onClick={closeOffcanvas}
                >
                  Orders
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/paid-orders"}
                  className="fs-6 fw-semibold nav-items"
                  onClick={closeOffcanvas}
                >
                  Paid Orders
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/contact-us"}
                  className="fs-6 fw-semibold nav-items"
                  // onClick={closeOffcanvas}
                  onClick={() => {
                    closeOffcanvas();
                    fetchContactData();
                  }}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="offcanvas-footer mobile-header-offcanvas-footer">
          <button
            className="btn text-black fs-6 fw-semibold d-flex align-items-center gap-2 mobile-header-logout-btn"
            type="button"
            onClick={() => {
              signOut();
              closeOffcanvas();
            }}
          >
            <FiLogOut className="fa-user-icon" />
            <p className="mb-0"> Sign Out</p>
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
