import React, { useState, useEffect } from "react";
import axios from "axios";
import { GrValidate } from "react-icons/gr";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import DataTable from "react-data-table-component";
import DatePicker from "react-date-picker";

const PaidOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalSale, setTotalSale] = useState(0);
  const token = localStorage.getItem("token");

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const fetchOrdersResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/getShipmentOrder`,
        {
          pageSize: "500",
          pageNo: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (fetchOrdersResponse?.data?.status === "Successful") {
        setOrders(fetchOrdersResponse?.data?.orderData);
      } else {
        setOrders([]);
      }

      const totalSalesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/total-sales${
          startDate && endDate
            ? `?startDate=${dayjs(startDate).format(
                "YYYY-MM-DD"
              )}&endDate=${dayjs(endDate).format("YYYY-MM-DD")}`
            : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (totalSalesResponse.data.status === "success") {
        setTotalSale(totalSalesResponse.data.totalSale);
      } else {
        console.error("Failed to fetch total sales");
      }
    } catch (error) {
      console.log("Paid Order not fetched: ", error);
      setOrders([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [token, startDate, endDate]);

  const calculateTotalQuantity = (order) => {
    return order?.orderDetail.reduce(
      (sum, detail) =>
        sum +
        detail?.subOrderDetail.reduce(
          (subSum, subDetail) => subSum + subDetail?.quantity,
          0
        ),
      0
    );
  };

  const filteredOrders = orders.filter((order) => {
    const orderDate = dayjs(order.orderDate); // Ensure orderDate is a dayjs object

    const isWithinDateRange =
      (!startDate || orderDate.isAfter(dayjs(startDate).subtract(1, "day"))) &&
      (!endDate || orderDate.isBefore(dayjs(endDate).add(1, "day")));

    return isWithinDateRange;
  });

  const columns = [
    {
      name: "Order ID",
      selector: (row) => row?.pkorderId,
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row?.clientName,
      sortable: true,
    },
    {
      name: "Client Email",
      selector: (row) => row?.clientEmail,
    },
    {
      name: "Client Phone",
      selector: (row) => row?.clientPhNo1,
    },
    {
      name: "Quantity",
      selector: (row) => calculateTotalQuantity(row),
    },
    {
      name: "Order Status",
      selector: (row) => row?.orderStatus,
      sortable: true,
    },
    {
      name: "Order Amount",
      selector: (row) => row?.orderAmount,
    },
    {
      name: "Order ID",
      // selector: (row) => row?.orderId,
      cell: (row) => (
        <div className="order-id-cell" title={row?.orderId}>
          {row?.orderId}
        </div>
      ),
    },
    {
      name: "Order In House Track Status",
      selector: (row) => row?.inHouseTrackStatus,
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => dayjs(row?.orderDate).format("YYYY-MM-DD"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            type="button"
            onClick={() => handleLogin(row)}
            className="paid-order-btn"
          >
            {row.isShipped === "1" ? (
              <FaCheckCircle color="green" className="paid-order-svg-btn" />
            ) : (
              <GrValidate className="paid-order-svg-btn" />
            )}
          </button>
        </>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
      },
    },
  };

  const handleLogin = async (order) => {
    // Check if the order is already shipped
    if (order.isShipped === "1") {
      toast.error("This product has already been validated.", {
        autoClose: 1000,
        position: "bottom-right",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/login`,
        {
          email: "mukeshkmwl@gmail.com",
          password: "Mukesh@123",
        }
      );

      if (response.data.token) {
        localStorage.setItem("shiprocketToken", response.data.token);
        await checkServiceability(order);
      } else {
        console.error("Login failed, token not received.");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const checkServiceability = async (order) => {
    const staticPickupPostcode = "400705"; // Static pickup postcode
    const staticWeight = 0.5; // Static weight

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shiprocket/serviceability`,
        {
          params: {
            pickup_postcode: staticPickupPostcode,
            delivery_postcode: order.pincode,
            cod: order.isCod,
            weight: staticWeight,
          },
        }
      );

      console.log("Serviceability response:", response.data);

      // Call createOrder after serviceability check
      await createOrder(order);
    } catch (error) {
      console.error("Error checking serviceability:", error);
    }
  };

  // serviceability api successfully after calling create shiprocket order
  const createOrder = async (order) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/createOrder`,
        {
          order_id: order.orderId,
          order_date: order.orderDate,
          pickup_location: "KARV ACC",
          channel_id: "",
          comment: "",
          reseller_name: "",
          company_name: "",
          billing_customer_name: order.clientName,
          billing_last_name: "",
          billing_address: order.clientAdd1,
          billing_address_2: order.clientAdd2,
          billing_isd_code: "",
          billing_city: order.city,
          billing_pincode: order.pincode,
          billing_state: order.state,
          billing_country: order.country,
          billing_email: order.clientEmail,
          billing_phone: order.clientPhNo1,
          billing_alternate_phone: order.clientPhNo2 || "",
          shipping_is_billing: "1",
          shipping_customer_name: "",
          shipping_last_name: "",
          shipping_address: "",
          shipping_address_2: "",
          shipping_city: "",
          shipping_pincode: "",
          shipping_country: "",
          shipping_state: "",
          shipping_email: "",
          shipping_phone: "",
          order_items: order.orderDetail.map((item) => ({
            name: item.prodName,
            sku: item.prodCode,
            // units: 1, // Assuming 1 unit per item
            // units: item.quantity, // Assuming 1 unit per item
            // units: item.quantity >= 1 ? item.quantity : 1, // Assuming 1 unit per item
            units: item.subOrderDetail[0].quantity, // Assuming 1 unit per item
            selling_price: parseFloat(item.prodPrice),
            discount: "",
            tax: "",
            hsn: "",
          })),
          payment_method: order.isPrepaid === "1" ? "Prepaid" : "COD",
          shipping_charges: "",
          giftwrap_charges: "",
          transaction_charges: "",
          total_discount: "",
          sub_total: parseFloat(order.orderAmount),
          length: "10", // Example values, adjust as needed
          breadth: "10",
          height: "10",
          weight: "0.80",
          ewaybill_no: "",
          customer_gstin: "",
          invoice_number: "",
          order_type: "",
        }
      );

      console.log("Create order response:", response.data);

      // After order creation, assign courier
      await assignCourier(
        response.data.shipment_id,
        response.data.order_id,
        order.orderId,
        order.clientName,
        order.clientEmail,
        order.pkorderId
      );
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  // shiprocket order api successfully after calling assign courier awb
  const assignCourier = async (
    shipmentId,
    shipOrderId,
    orderID,
    orderClientName,
    orderClientEmail,
    pkOrderId
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/assign-awb`,
        {
          shipment_id: shipmentId,
        }
      );

      console.log("Assign courier response:", response.data);
      // Ensure response.data.response.data contains awb_code
      const awbCode = response.data.response?.data?.awb_code;

      if (awbCode) {
        console.log("Get awb code:", awbCode);

        // After assigning the courier, generate pickup
        await generatePickup(
          shipmentId,
          shipOrderId,
          orderID,
          orderClientName,
          orderClientEmail,
          pkOrderId,
          awbCode
        );
      } else {
        console.error("AWB code not found in the response.");
      }
    } catch (error) {
      console.error("Error assigning courier:", error);
    }
  };

  // awb api successfully after calling generate pickup
  const generatePickup = async (
    shipmentId,
    shipOrderId,
    orderID,
    orderClientName,
    orderClientEmail,
    pkOrderId,
    awbCode
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/generate-pickup`,
        {
          shipment_id: String(shipmentId),
        }
      );

      console.log("Pickup generated:", response.data);

      // After generating pickup, generate manifest
      await generateManifest(
        shipmentId,
        shipOrderId,
        orderID,
        orderClientName,
        orderClientEmail,
        pkOrderId,
        awbCode
      );
    } catch (error) {
      console.error("Error generating pickup:", error);
    }
  };

  // generate pickup api successfully after calling generate manifest
  const generateManifest = async (
    shipmentId,
    shipOrderId,
    orderID,
    orderClientName,
    orderClientEmail,
    pkOrderId,
    awbCode
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/generate-manifest`,
        {
          shipment_id: String(shipmentId),
        }
      );

      console.log("Manifest generated:", response.data);

      const manifestUrl1 = response.data.manifest_url;

      // After generating manifest, print the manifest
      await printManifest(
        shipOrderId,
        shipmentId,
        orderID,
        orderClientName,
        orderClientEmail,
        pkOrderId,
        awbCode,
        manifestUrl1
      );
    } catch (error) {
      console.error("Error generating manifest:", error);
    }
  };

  // generate manifest api successfully after calling generate print manifest url
  const printManifest = async (
    shipOrderId,
    shipmentId,
    orderID,
    orderClientName,
    orderClientEmail,
    pkOrderId,
    awbCode,
    manifestUrl1
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/print-manifest`,
        {
          order_ids: [shipOrderId],
        }
      );

      console.log("Manifest printed:", response.data);
      const manifestUrl2 = response.data.manifest_url;

      // After printing manifest, generate the shipping label
      await generateLabel(
        shipOrderId,
        shipmentId,
        orderID,
        orderClientName,
        orderClientEmail,
        pkOrderId,
        awbCode,
        manifestUrl1,
        manifestUrl2
      );
    } catch (error) {
      console.error("Error printing manifest:", error);
    }
  };

  // generate print manifest url api successfully after calling generate label
  const generateLabel = async (
    shipOrderId,
    shipmentIds,
    orderID,
    orderClientName,
    orderClientEmail,
    pkOrderId,
    awbCode,
    manifestUrl1,
    manifestUrl2
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/generate-label`,
        {
          shipment_id: [shipmentIds],
        }
      );

      console.log("Shipping label generated:", response.data);
      const labelUrl = response.data.label_url;

      // After shipping label, print invoices
      await printInvoice(
        shipOrderId,
        shipmentIds,
        orderID,
        orderClientName,
        orderClientEmail,
        pkOrderId,
        awbCode,
        manifestUrl1,
        manifestUrl2,
        labelUrl
      );
    } catch (error) {
      console.error("Error generating shipping label:", error);
    }
  };

  // generate label api successfully after calling print invoices
  const printInvoice = async (
    shipOrderId,
    shipmentIds,
    orderID,
    orderClientName,
    orderClientEmail,
    pkOrderId,
    awbCode,
    manifestUrl1,
    manifestUrl2,
    labelUrl
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/generate-invoice`,
        {
          ids: [shipOrderId],
        }
      );

      console.log("Shipping print invoices:", response.data);
      const invoiceUrl = response.data.invoice_url;

      // After print invoices, call awb for track order
      await trackAWB(
        shipOrderId,
        shipmentIds,
        orderID,
        orderClientName,
        orderClientEmail,
        awbCode
      );

      // call the mailing API all PDF send to this API
      await sendShiprocketDocuments(
        shipOrderId,
        shipmentIds,
        orderID,
        awbCode,
        manifestUrl1,
        manifestUrl2,
        labelUrl,
        invoiceUrl
      );

      // call the mailing API all user details send to this API
      await sendUserInformation(
        shipOrderId,
        shipmentIds,
        orderID,
        orderClientName,
        orderClientEmail,
        awbCode,
        manifestUrl1,
        manifestUrl2,
        labelUrl,
        invoiceUrl
      );

      // call the pkOrderId API
      await updateUserClick(pkOrderId);
    } catch (error) {
      console.error("Error print invoices shipping:", error);
    }
  };

  // track AWB last api
  const trackAWB = async (
    shipOrderId,
    shipmentIds,
    orderID,
    orderClientName,
    orderClientEmail,
    awbCode
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shiprocket/track-shipment/${awbCode}`
      );

      console.log("Serviceability response:", response.data);
    } catch (error) {
      console.error("Error checking serviceability:", error);
    }
  };

  // final API for all PDFs url
  const sendShiprocketDocuments = async (
    shipOrderId,
    shipmentIds,
    orderID,
    awbCode,
    manifestUrl1,
    manifestUrl2,
    labelUrl,
    invoiceUrl
  ) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    const data = {
      manifestUrl1,
      manifestUrl2,
      labelUrl,
      invoiceUrl,
      awbNumber: awbCode,
      orderNumber: orderID,
      shipmentNumber: shipmentIds,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/sentShiprocketDocuments`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Log the response data
      console.log("Response:", response.data);
    } catch (error) {
      // Log any error that occurs
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // final API for all user details url
  const sendUserInformation = async (
    shipOrderId,
    shipmentIds,
    orderID,
    orderClientName,
    orderClientEmail,
    awbCode,
    manifestUrl1,
    manifestUrl2,
    labelUrl,
    invoiceUrl
  ) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    const data = {
      orderId: orderID,
      userName: orderClientName,
      userEmail: orderClientEmail,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/sentOrderConfirmationEmailToUser`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const updateUserClick = async (pkOrderId) => {
    const requestBody = {
      isShipped: "1",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/update/${pkOrderId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      console.log("Order updated successfully:", result);
      fetchOrders();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  if (loading) {
    return (
      <div className="loading-data">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <section className="paid-order-page">
      <div className="our-container px-3">
        <div className="inner-container">
          <div className="paid-order-heading mb-4">
            <h2 className="m-0">Paid Orders</h2>
            {filteredOrders.length > 0 && (
              <p className="total-sale">Total Sale : {totalSale}</p>
            )}
          </div>
          <div className="product-paid-orders-filter-block-row mb-3">
            <div className="start-date-block">
              <DatePicker
                className="start-date"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date ? dayjs(date) : null);
                }}
                placeholderText="Start Date"
              />
            </div>
            <div className="end-date-block">
              <DatePicker
                className="end-date"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date ? dayjs(date) : null);
                }}
                placeholderText="End Date"
              />
            </div>
          </div>
          <div className="paid-orders-body">
            {loading ? (
              <p className="error-show-para">Loading...</p>
            ) : filteredOrders.length === 0 ? (
              <div className="error-show-para">
                <p>No orders found</p>
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={filteredOrders}
                pagination
                highlightOnHover
                customStyles={customStyles}
                responsive
                paginationPerPage={7}
                paginationRowsPerPageOptions={[7, 10, 20]}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaidOrders;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { GrValidate } from "react-icons/gr";
// import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
// import { toast } from "react-toastify";

// const PaidOrders = () => {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [totalPages, setTotalPages] = useState(0);

//   useEffect(() => {
//     fetchOrders();
//   }, [currentPage, pageSize]);

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   // fetching all order and showing in table
//   const fetchOrders = async () => {
//     // setLoading(true);
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/order/getShipmentOrder`,
//         {
//           pkOrdID: "",
//           clientName: "",
//           clientEmail: "",
//           clientPhNo: "",
//           clientAdd: "",
//           orderId: "",
//           paymentId: "",
//           pageSize: pageSize,
//           pageNo: currentPage,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );

//       if (response.data.status === "Successful") {
//         setOrders(response.data.orderData);
//         setTotalPages(Math.ceil(response.data.totalResult / pageSize));
//         setError(null);
//       } else {
//         setOrders([]);
//         setTotalPages(0);
//         setError("No orders found");
//       }
//     } catch (error) {
//       setError("Error fetching orders");
//     } finally {
//       // setLoading(false);
//     }
//   };

//   // now i hit login api in validity button in table action button
//   const handleLogin = async (order) => {
//     // Check if the order is already shipped
//     if (order.isShipped === "1") {
//       toast.error("This product has already been validated.", {
//         autoClose: 1000,
//         position: "bottom-right",
//       });
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/shiprocket/login`,
//         {
//           email: "mukeshkmwl@gmail.com",
//           password: "Mukesh@123",
//         }
//       );

//       if (response.data.token) {
//         localStorage.setItem("shiprocketToken", response.data.token);
//         await checkServiceability(order);
//       } else {
//         console.error("Login failed, token not received.");
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//     }
//   };

//   const checkServiceability = async (order) => {
//     const staticPickupPostcode = "400705"; // Static pickup postcode
//     const staticWeight = 0.5; // Static weight

//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/shiprocket/serviceability`,
//         {
//           params: {
//             pickup_postcode: staticPickupPostcode,
//             delivery_postcode: order.pincode,
//             cod: order.isCod,
//             weight: staticWeight,
//           },
//         }
//       );

//       console.log("Serviceability response:", response.data);

//       // Call createOrder after serviceability check
//       await createOrder(order);
//     } catch (error) {
//       console.error("Error checking serviceability:", error);
//     }
//   };

//   // serviceability api successfully after calling create shiprocket order
//   const createOrder = async (order) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/shiprocket/createOrder`,
//         {
//           order_id: order.orderId,
//           order_date: order.orderDate,
//           pickup_location: "KARV ACC",
//           channel_id: "",
//           comment: "",
//           reseller_name: "",
//           company_name: "",
//           billing_customer_name: order.clientName,
//           billing_last_name: "",
//           billing_address: order.clientAdd1,
//           billing_address_2: order.clientAdd2,
//           billing_isd_code: "",
//           billing_city: order.city,
//           billing_pincode: order.pincode,
//           billing_state: order.state,
//           billing_country: order.country,
//           billing_email: order.clientEmail,
//           billing_phone: order.clientPhNo1,
//           billing_alternate_phone: order.clientPhNo2 || "",
//           shipping_is_billing: "1",
//           shipping_customer_name: "",
//           shipping_last_name: "",
//           shipping_address: "",
//           shipping_address_2: "",
//           shipping_city: "",
//           shipping_pincode: "",
//           shipping_country: "",
//           shipping_state: "",
//           shipping_email: "",
//           shipping_phone: "",
//           order_items: order.orderDetail.map((item) => ({
//             name: item.prodName,
//             sku: item.prodCode,
//             // units: 1, // Assuming 1 unit per item
//             // units: item.quantity, // Assuming 1 unit per item
//             // units: item.quantity >= 1 ? item.quantity : 1, // Assuming 1 unit per item
//             units: item.subOrderDetail[0].quantity, // Assuming 1 unit per item
//             selling_price: parseFloat(item.prodPrice),
//             discount: "",
//             tax: "",
//             hsn: "",
//           })),
//           payment_method: order.isPrepaid === "1" ? "Prepaid" : "COD",
//           shipping_charges: "",
//           giftwrap_charges: "",
//           transaction_charges: "",
//           total_discount: "",
//           sub_total: parseFloat(order.orderAmount),
//           length: "10", // Example values, adjust as needed
//           breadth: "10",
//           height: "10",
//           weight: "0.80",
//           ewaybill_no: "",
//           customer_gstin: "",
//           invoice_number: "",
//           order_type: "",
//         }
//       );

//       console.log("Create order response:", response.data);

//       // After order creation, assign courier
//       await assignCourier(
//         response.data.shipment_id,
//         response.data.order_id,
//         order.orderId,
//         order.clientName,
//         order.clientEmail,
//         order.pkorderId
//       );
//     } catch (error) {
//       console.error("Error creating order:", error);
//     }
//   };

//   // shiprocket order api successfully after calling assign courier awb
//   const assignCourier = async (
//     shipmentId,
//     shipOrderId,
//     orderID,
//     orderClientName,
//     orderClientEmail,
//     pkOrderId
//   ) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/shiprocket/assign-awb`,
//         {
//           shipment_id: shipmentId,
//         }
//       );

//       console.log("Assign courier response:", response.data);
//       // Ensure response.data.response.data contains awb_code
//       const awbCode = response.data.response?.data?.awb_code;

//       if (awbCode) {
//         console.log("Get awb code:", awbCode);

//         // After assigning the courier, generate pickup
//         await generatePickup(
//           shipmentId,
//           shipOrderId,
//           orderID,
//           orderClientName,
//           orderClientEmail,
//           pkOrderId,
//           awbCode
//         );
//       } else {
//         console.error("AWB code not found in the response.");
//       }
//     } catch (error) {
//       console.error("Error assigning courier:", error);
//     }
//   };

//   // awb api successfully after calling generate pickup
//   const generatePickup = async (
//     shipmentId,
//     shipOrderId,
//     orderID,
//     orderClientName,
//     orderClientEmail,
//     pkOrderId,
//     awbCode
//   ) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/shiprocket/generate-pickup`,
//         {
//           shipment_id: String(shipmentId),
//         }
//       );

//       console.log("Pickup generated:", response.data);

//       // After generating pickup, generate manifest
//       await generateManifest(
//         shipmentId,
//         shipOrderId,
//         orderID,
//         orderClientName,
//         orderClientEmail,
//         pkOrderId,
//         awbCode
//       );
//     } catch (error) {
//       console.error("Error generating pickup:", error);
//     }
//   };

//   // generate pickup api successfully after calling generate manifest
//   const generateManifest = async (
//     shipmentId,
//     shipOrderId,
//     orderID,
//     orderClientName,
//     orderClientEmail,
//     pkOrderId,
//     awbCode
//   ) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/shiprocket/generate-manifest`,
//         {
//           shipment_id: String(shipmentId),
//         }
//       );

//       console.log("Manifest generated:", response.data);

//       const manifestUrl1 = response.data.manifest_url;

//       // After generating manifest, print the manifest
//       await printManifest(
//         shipOrderId,
//         shipmentId,
//         orderID,
//         orderClientName,
//         orderClientEmail,
//         pkOrderId,
//         awbCode,
//         manifestUrl1
//       );
//     } catch (error) {
//       console.error("Error generating manifest:", error);
//     }
//   };

//   // generate manifest api successfully after calling generate print manifest url
//   const printManifest = async (
//     shipOrderId,
//     shipmentId,
//     orderID,
//     orderClientName,
//     orderClientEmail,
//     pkOrderId,
//     awbCode,
//     manifestUrl1
//   ) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/shiprocket/print-manifest`,
//         {
//           order_ids: [shipOrderId],
//         }
//       );

//       console.log("Manifest printed:", response.data);
//       const manifestUrl2 = response.data.manifest_url;

//       // After printing manifest, generate the shipping label
//       await generateLabel(
//         shipOrderId,
//         shipmentId,
//         orderID,
//         orderClientName,
//         orderClientEmail,
//         pkOrderId,
//         awbCode,
//         manifestUrl1,
//         manifestUrl2
//       );
//     } catch (error) {
//       console.error("Error printing manifest:", error);
//     }
//   };

//   // generate print manifest url api successfully after calling generate label
//   const generateLabel = async (
//     shipOrderId,
//     shipmentIds,
//     orderID,
//     orderClientName,
//     orderClientEmail,
//     pkOrderId,
//     awbCode,
//     manifestUrl1,
//     manifestUrl2
//   ) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/shiprocket/generate-label`,
//         {
//           shipment_id: [shipmentIds],
//         }
//       );

//       console.log("Shipping label generated:", response.data);
//       const labelUrl = response.data.label_url;

//       // After shipping label, print invoices
//       await printInvoice(
//         shipOrderId,
//         shipmentIds,
//         orderID,
//         orderClientName,
//         orderClientEmail,
//         pkOrderId,
//         awbCode,
//         manifestUrl1,
//         manifestUrl2,
//         labelUrl
//       );
//     } catch (error) {
//       console.error("Error generating shipping label:", error);
//     }
//   };

//   // generate label api successfully after calling print invoices
//   const printInvoice = async (
//     shipOrderId,
//     shipmentIds,
//     orderID,
//     orderClientName,
//     orderClientEmail,
//     pkOrderId,
//     awbCode,
//     manifestUrl1,
//     manifestUrl2,
//     labelUrl
//   ) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/shiprocket/generate-invoice`,
//         {
//           ids: [shipOrderId],
//         }
//       );

//       console.log("Shipping print invoices:", response.data);
//       const invoiceUrl = response.data.invoice_url;

//       // After print invoices, call awb for track order
//       await trackAWB(
//         shipOrderId,
//         shipmentIds,
//         orderID,
//         orderClientName,
//         orderClientEmail,
//         awbCode
//       );

//       // call the mailing API all PDF send to this API
//       await sendShiprocketDocuments(
//         shipOrderId,
//         shipmentIds,
//         orderID,
//         awbCode,
//         manifestUrl1,
//         manifestUrl2,
//         labelUrl,
//         invoiceUrl
//       );

//       // call the mailing API all user details send to this API
//       await sendUserInformation(
//         shipOrderId,
//         shipmentIds,
//         orderID,
//         orderClientName,
//         orderClientEmail,
//         awbCode,
//         manifestUrl1,
//         manifestUrl2,
//         labelUrl,
//         invoiceUrl
//       );

//       // call the pkOrderId API
//       await updateUserClick(pkOrderId);
//     } catch (error) {
//       console.error("Error print invoices shipping:", error);
//     }
//   };

//   // track AWB last api
//   const trackAWB = async (
//     shipOrderId,
//     shipmentIds,
//     orderID,
//     orderClientName,
//     orderClientEmail,
//     awbCode
//   ) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/shiprocket/track-shipment/${awbCode}`
//       );

//       console.log("Serviceability response:", response.data);
//     } catch (error) {
//       console.error("Error checking serviceability:", error);
//     }
//   };

//   // final API for all PDFs url
//   const sendShiprocketDocuments = async (
//     shipOrderId,
//     shipmentIds,
//     orderID,
//     awbCode,
//     manifestUrl1,
//     manifestUrl2,
//     labelUrl,
//     invoiceUrl
//   ) => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.error("No Shiprocket token found.");
//       return;
//     }

//     const data = {
//       manifestUrl1,
//       manifestUrl2,
//       labelUrl,
//       invoiceUrl,
//       awbNumber: awbCode,
//       orderNumber: orderID,
//       shipmentNumber: shipmentIds,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/order/sentShiprocketDocuments`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       // Log the response data
//       console.log("Response:", response.data);
//     } catch (error) {
//       // Log any error that occurs
//       console.error(
//         "Error:",
//         error.response ? error.response.data : error.message
//       );
//     }
//   };

//   // final API for all user details url
//   const sendUserInformation = async (
//     shipOrderId,
//     shipmentIds,
//     orderID,
//     orderClientName,
//     orderClientEmail,
//     awbCode,
//     manifestUrl1,
//     manifestUrl2,
//     labelUrl,
//     invoiceUrl
//   ) => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.error("No Shiprocket token found.");
//       return;
//     }

//     const data = {
//       orderId: orderID,
//       userName: orderClientName,
//       userEmail: orderClientEmail,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/order/sentOrderConfirmationEmailToUser`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       // Log the response data
//       console.log("Response:", response.data);
//     } catch (error) {
//       // Log any error that occurs
//       console.error(
//         "Error:",
//         error.response ? error.response.data : error.message
//       );
//     }
//   };

//   const updateUserClick = async (pkOrderId) => {
//     const requestBody = {
//       isShipped: "1",
//     };

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/order/update/${pkOrderId}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(requestBody),
//         }
//       );

//       if (!response.ok) {
//         throw new Error(`Error: ${response.status}`);
//       }

//       const result = await response.json();
//       console.log("Order updated successfully:", result);
//       fetchOrders();
//     } catch (error) {
//       console.error("Error updating order:", error);
//     }
//   };

//   if (loading) {
//     return (
//       <div className="loading-data">
//         <p>Loading...</p>
//       </div>
//     );
//   }

//   return (
//     <section className="paid-order-page">
//       <div className="our-container px-3">
//         <div className="inner-container">
//           <div className="paid-order-heading mb-4">
//             <h2>Paid Orders</h2>
//           </div>
//           {orders.length > 0 ? (
//             <div className="paid-order-page-body table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">#</th>
//                     <th scope="col">Client Name</th>
//                     <th scope="col">Email</th>
//                     <th scope="col">Phone</th>
//                     <th scope="col">Address</th>
//                     <th scope="col">Quantity</th>
//                     <th scope="col">Order Status</th>
//                     <th scope="col">Order Amount</th>
//                     <th scope="col">Order ID</th>
//                     <th scope="col">Payment ID</th>
//                     <th scope="col">Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {orders.map((order, index) => {
//                     // Calculate the total quantity for the current order
//                     const totalQuantity = order.orderDetail.reduce(
//                       (total, product) => {
//                         return (
//                           total +
//                           product.subOrderDetail.reduce(
//                             (subTotal, subOrder) =>
//                               subTotal + subOrder.quantity,
//                             0
//                           )
//                         );
//                       },
//                       0
//                     );

//                     return (
//                       <tr key={order.pkorderId}>
//                         <th scope="row">
//                           {(currentPage - 1) * pageSize + index + 1}
//                         </th>
//                         <td>{order.clientName}</td>
//                         <td>{order.clientEmail}</td>
//                         <td>{order.clientPhNo1 || order.clientPhNo1}</td>
//                         <td>{order.clientAdd1 || order.clientAdd2}</td>
//                         <td>{totalQuantity}</td>{" "}
//                         {/* Display total quantity here */}
//                         <td>{order.orderStatus}</td>
//                         <td>{order.orderAmount}</td>
//                         <td>{order.orderId}</td>
//                         <td>{order.paymentId || "N/A"}</td>
//                         <td>
//                           <button
//                             className="paid-order-btn"
//                             onClick={() => handleLogin(order)}
//                           >
//                             {order.isShipped === "1" ? (
//                               <FaCheckCircle color="green" />
//                             ) : (
//                               <GrValidate />
//                             )}
//                           </button>
//                         </td>
//                       </tr>
//                     );
//                   })}
//                   {/* {orders.map((order, index) => (
//                     <tr key={order.pkorderId}>
//                       <th scope="row">
//                         {(currentPage - 1) * pageSize + index + 1}
//                       </th>
//                       <td>{order.clientName}</td>
//                       <td>{order.clientEmail}</td>
//                       <td>{order.clientPhNo1 || order.clientPhNo1}</td>
//                       <td>{order.clientAdd1 || order.clientAdd2}</td>
//                       <td>Quantity</td>
//                       <td>{order.orderStatus}</td>
//                       <td>{order.orderAmount}</td>
//                       <td>{order.orderId}</td>
//                       <td>{order.paymentId || "N/A"}</td>
//                       <td>
//                         <button
//                           className="paid-order-btn"
//                           onClick={() => handleLogin(order)}
//                         >
//                           {order.isShipped === "1" ? (
//                             <FaCheckCircle color="green" />
//                           ) : (
//                             <GrValidate />
//                           )}
//                         </button>
//                       </td>
//                     </tr>
//                   ))} */}
//                 </tbody>
//               </table>
//               <div className="pagination-controls">
//                 <label>
//                   Show
//                   <select
//                     value={pageSize}
//                     onChange={(e) => setPageSize(Number(e.target.value))}
//                     className="mx-2 p-1"
//                   >
//                     <option value={10}>10</option>
//                     <option value={15}>15</option>
//                     <option value={20}>20</option>
//                   </select>
//                   Orders per Page
//                 </label>
//                 <div className="pagination-buttons">
//                   <button
//                     className="pagination-btn"
//                     onClick={() => handlePageChange(currentPage - 1)}
//                     disabled={currentPage === 1}
//                   >
//                     Previous
//                   </button>
//                   <span>
//                     Page {currentPage} of {totalPages}
//                   </span>
//                   <button
//                     className="pagination-btn"
//                     onClick={() => handlePageChange(currentPage + 1)}
//                     disabled={currentPage >= totalPages}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <>
//               <div className="loading-data">
//                 <p>No Paid Orders found.</p>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default PaidOrders;
