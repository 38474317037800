import React, { createContext, useState, useEffect } from "react";

// Create the BusinessToBusinessContext Context
export const BusinessToBusinessContext = createContext();

// Create the BusinessToBusinessContext Provider
export const BusinessToBusinessContextProvider = ({ children }) => {
  const [btobProducts, setBtobProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);

  const fetchBtobData = async (isB2B = 0) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pageNo: currentPage,
            pageSize: pageSize,
            isB2B: isB2B === 1 ? 1 : 0,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setBtobProducts(data.masterData);
      setTotalPages(Math.ceil(data.totalResult / pageSize));
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBtobData(1);
  }, [currentPage, pageSize]);

  const updateCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(1);
  };

  // Function to get BusinessToBusinessContext details by ID from the API
  const getBtobProductDetails = async (productId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkProdId: productId,
            pageNo: 1,
            pageSize: 1,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.masterData[0];
    } catch (error) {
      console.error("Fetch error:", error);
      return null;
    }
  };

  return (
    <BusinessToBusinessContext.Provider
      value={{
        btobProducts,
        totalPages,
        currentPage,
        updateCurrentPage,
        pageSize,
        handlePageSizeChange,
        loading,
        getBtobProductDetails,
        fetchBtobData,
      }}
    >
      {children}
    </BusinessToBusinessContext.Provider>
  );
};