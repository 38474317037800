import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { MdShoppingCart, MdRemoveShoppingCart, MdPaid } from "react-icons/md";
import { RiRefund2Line } from "react-icons/ri";
import { GiTakeMyMoney } from "react-icons/gi";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedChart, setSelectedChart] = useState("doughnut");

  // admin panel design and developed info
  const adminPanelDevelopedBy = localStorage.setItem(
    "adminPanelDevelopedBy",
    process.env.REACT_APP_OWNER_FOR_DEVELOPMENT
  );

  const token = `${localStorage.getItem("token")}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/insight`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  // console.log("data    ", data)

  if (loading) return <p>Loading...</p>;

  const chartData = {
    labels: data.map((item) => item.Module),
    datasets: [
      {
        label: "Counts",
        data: data.map((item) => item.count),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const renderChart = () => {
    switch (selectedChart) {
      case "doughnut":
        return <Doughnut data={chartData} className="main-chart" />;
      case "pie":
        return <Pie data={chartData} className="main-chart" />;
      default:
        return <Doughnut data={chartData} className="main-chart" />;
    }
  };

  const handleChartChange = (e) => {
    setSelectedChart(e.target.value);
  };

  return (
    <>
      <section className="dashboard-page">
        <div className="our-container px-3">
          <div className="inner-container">
            <div className="dashboard-heading mb-4">
              <h2>Dashboard</h2>
              <select value={selectedChart} onChange={handleChartChange}>
                <option value="doughnut">Doughnut Chart</option>
                <option value="pie">Pie Chart</option>
              </select>
            </div>
            <div className="dashboard-body">
              <div className="row mx-0 gap-4 my-dashboard">
                <div className="col-md-12 col-lg-7 col-12 px-0 data-show-left-block">
                  <div className="px-0">
                    <div className="data-block">
                      {data.map((item) => (
                        <div key={item.Module} className="cart-block">
                          <div className="icon-block">
                            {item.Module === "COD Orders" && (
                              <MdShoppingCart className="icons" />
                            )}
                            {item.Module === "Paid Orders" && (
                              <MdPaid className="icons" />
                            )}
                            {item.Module === "Order Refunded" && (
                              <MdRemoveShoppingCart className="icons" />
                            )}
                            {item.Module === "Total Sale" && (
                              <GiTakeMyMoney className="icons" />
                            )}
                          </div>
                          <div className="group-data mb-4">
                            <h3 className="m-0">{item.Module}</h3>
                            <p className="fs-4 gd-p">{item.count}</p>
                          </div>
                          <p className="mb-0 cd-p">Current active records</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 col-12 chart-show-right-block">
                  <div className="chart-show">{renderChart()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
