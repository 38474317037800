import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ProductProvider } from "./contexts/ProductContext";
import { AuthProvider } from "./contexts/AuthContext";
import { BusinessToBusinessContextProvider } from "./contexts/BusinessToBusinessContext";
import { ContactProvider } from "./contexts/ContactContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContactProvider>
    <BusinessToBusinessContextProvider>
      <ProductProvider>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </ProductProvider>
    </BusinessToBusinessContextProvider>
  </ContactProvider>
);
