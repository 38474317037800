import React, { createContext, useState } from "react";

// Create the Contact Context
export const ContactContext = createContext();

// Create the Contact Provider
export const ContactProvider = ({ children }) => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false); // New state to track fetch status

  const fetchContactData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/contact/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            userName: "",
            userEmail: "",
            phoneNo: "",
            message: "",
            fromDate: "",
            toDate: "",
            pageSize: pageSize,
            pageNo: currentPage,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setContacts(result.data || []);
      setTotalPages(Math.ceil(result.totalResult / pageSize));
      setHasFetched(true); // Mark as fetched
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateCurrentPage = (page) => {
    setCurrentPage(page);
    if (hasFetched) fetchContactData(); // Only fetch if user has clicked 'Contact Us'
  };

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(1);
    if (hasFetched) fetchContactData(); // Only fetch if user has clicked 'Contact Us'
  };

  return (
    <ContactContext.Provider
      value={{
        contacts,
        totalPages,
        currentPage,
        updateCurrentPage,
        pageSize,
        handlePageSizeChange,
        loading,
        fetchContactData, // Only call when needed
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

// import React, { createContext, useState, useEffect } from "react";

// // Create the Contact Context
// export const ContactContext = createContext();

// // Create the Contact Provider
// export const ContactProvider = ({ children }) => {
//   const [contacts, setContacts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [loading, setLoading] = useState(true);

//   const fetchContactData = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/contact/search`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//           body: JSON.stringify({
//             userName: "",
//             userEmail: "",
//             phoneNo: "",
//             message: "",
//             fromDate: "",
//             toDate: "",
//             pageSize: pageSize,
//             pageNo: currentPage,
//           }),
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }
//       const result = await response.json();
//       setContacts(result.data || []);
//       setTotalPages(Math.ceil(result.totalResult / pageSize));
//     } catch (error) {
//       console.error("Fetch error:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchContactData();
//   }, [currentPage, pageSize]);

//   const updateCurrentPage = (page) => {
//     setCurrentPage(page);
//   };

//   const handlePageSizeChange = (e) => {
//     const size = parseInt(e.target.value, 10);
//     setPageSize(size);
//     setCurrentPage(1);
//   };

//   return (
//     <ContactContext.Provider
//       value={{
//         contacts,
//         totalPages,
//         currentPage,
//         updateCurrentPage,
//         pageSize,
//         handlePageSizeChange,
//         loading,
//         fetchContactData,
//       }}
//     >
//       {children}
//     </ContactContext.Provider>
//   );
// };
