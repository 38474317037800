import React, { useState, useEffect } from "react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { FaEye } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import DatePicker from "react-date-picker";
import DataTable from "react-data-table-component";

const Orders = () => {
  const [orderIdFilter, setOrderIdFilter] = useState("");
  const [paymentIdFilter, setPaymentIdFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [orderStatusFilter, setOrderStatusFilter] = useState("");
  const [orders, setOrders] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [inHouseTrackStatus, setInHouseTrackStatus] = useState(null);
  const [inHouseTrackStatusRemark, setInHouseTrackStatusRemark] =
    useState(null);
  const [inHouseTrackStatusResult, setInHouseTrackStatusResult] =
    useState(null);
  const [error, setError] = useState("");
  const [inHouseTrackStatusFilter, setInHouseTrackStatusFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalSale, setTotalSale] = useState(0);
  const token = localStorage.getItem("token");

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const fetchOrdersResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/search`,
        {
          pkOrdID: "",
          fkCredentialMasterId: "",
          clientName: "",
          clientEmail: "",
          clientPhNo: "",
          clientAdd: "",
          orderStatus: ["COD", "paid"],
          inHouseTrackStatus: [
            "New Order",
            "Quality Check",
            "Labeling Process",
            "Order Packing",
          ],
          orderId: "",
          paymentId: "",
          pageSize: "500",
          pageNo: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (fetchOrdersResponse?.data?.status === "Successful") {
        setOrders(fetchOrdersResponse?.data?.orderData);

        setInHouseTrackStatus(
          fetchOrdersResponse?.data?.orderData[0]?.inHouseTrackStatus
        );

        setInHouseTrackStatusResult(
          fetchOrdersResponse?.data?.orderData[0]?.inHouseTrackStatusResult
        );
      } else {
        setOrders([]);
      }

      const totalSalesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/total-sales${
          startDate && endDate
            ? `?startDate=${dayjs(startDate).format(
                "YYYY-MM-DD"
              )}&endDate=${dayjs(endDate).format("YYYY-MM-DD")}`
            : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (totalSalesResponse.data.status === "success") {
        setTotalSale(totalSalesResponse.data.totalSale);
      } else {
        console.error("Failed to fetch total sales");
      }
    } catch (error) {
      console.log("Order not fetched : ", error);
      setOrders([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [token, startDate, endDate]);

  useEffect(() => {
    const fetchOrderStatusList = async () => {
      try {
        const fetchOrderStatusListResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/order/orderStatusList`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (fetchOrderStatusListResponse?.ok) {
          const data = await fetchOrderStatusListResponse.json();
          if (data.status === "Successful" && Array.isArray(data.option)) {
            setOrderStatusList(data?.option);
          } else {
            console.error(
              "Unexpected fetchOrderStatusListResponse structure : ",
              data
            );
            setOrderStatusList([]);
          }
        } else {
          console.error("Failed to fetch order status list");
          setOrderStatusList([]);
        }
      } catch (error) {
        console.error("Error fetching order status list : ", error);
      }
    };

    fetchOrderStatusList();
  }, [token]);

  useEffect(() => {
    // Jab bhi inHouseTrackStatus change ho, radio button clear ho jaye
    setInHouseTrackStatusResult(null);
  }, [inHouseTrackStatus]);

  const filteredOrders = orders.filter((order) => {
    const orderDate = dayjs(order.orderDate);

    const isWithinDateRange =
      (!startDate || orderDate.isAfter(dayjs(startDate).subtract(1, "day"))) &&
      (!endDate || orderDate.isBefore(dayjs(endDate).add(1, "day")));

    return (
      isWithinDateRange &&
      (!orderIdFilter ||
        order?.orderId?.toLowerCase().includes(orderIdFilter.toLowerCase())) &&
      (!paymentIdFilter ||
        order?.paymentId
          ?.toLowerCase()
          .includes(paymentIdFilter.toLowerCase())) &&
      (!emailFilter ||
        order?.clientEmail
          ?.toLowerCase()
          .includes(emailFilter.toLowerCase())) &&
      (!orderStatusFilter || order.orderStatus === orderStatusFilter) &&
      (!inHouseTrackStatusFilter ||
        order.inHouseTrackStatus === inHouseTrackStatusFilter)
    );
  });

  const calculateTotalQuantity = (order) => {
    return order?.orderDetail.reduce(
      (sum, detail) =>
        sum +
        detail?.subOrderDetail.reduce(
          (subSum, subDetail) => subSum + subDetail?.quantity,
          0
        ),
      0
    );
  };

  const handleUpdateStatus = async (e) => {
    e.preventDefault();

    const updatedInHouseTrackStatusResult =
      inHouseTrackStatus === "Billing" ? "Approved" : inHouseTrackStatusResult;

    if (!updatedInHouseTrackStatusResult) {
      setError("Please select an option for In-House Track Status Result.");
      return;
    } else {
      setError("");
    }

    try {
      const updateResponse = await axios.put(
        `${process.env.REACT_APP_API_URL}/order/update/${orders?.[0].pkorderId}`,
        {
          inHouseTrackStatus,
          inHouseTrackStatusRemark,
          inHouseTrackStatusResult: updatedInHouseTrackStatusResult,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("Update Response:", updateResponse);

      if (
        updateResponse.data.status == "Successful" &&
        updateResponse.data.code == 200
      ) {
        // Call fetchOrders again after successful update
        fetchOrders();

        const emailResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/order/sentOrderStatusUpdateEmailToAdmin`,
          {
            productName: orders?.[0]?.orderDetail?.[0].prodName,
            productCode:
              orders?.[0]?.orderDetail?.[0].subOrderDetail?.[0].prodCode,
            orderId: orders?.[0]?.orderId,
            orderDate: orders?.[0]?.orderDate.split("T")[0],
            orderStatus: inHouseTrackStatus,
            orderStatusRemark: inHouseTrackStatusRemark,
            orderStatusResult: inHouseTrackStatusResult,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (emailResponse.data.status === "success") {
          setInHouseTrackStatusRemark(null);
          setInHouseTrackStatusResult(null);

          const modal = document.getElementById("editOrderModal");
          const modalBackdrop = document.querySelector(".modal-backdrop");
          modal.classList.remove("show");
          modal.style.display = "none";
          modalBackdrop && modalBackdrop.remove();

          toast.success("Order status updated and email sent to admin!", {
            position: "top-right",
            autoClose: 1000,
          });
        } else {
          toast.error("Failed to send email to admin.", {
            position: "top-right",
            autoClose: 1000,
          });
        }
      } else {
        toast.error("Failed to update order status.", {
          position: "top-right",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error updating order status:", error);

      toast.error("An error occurred while updating the order status.", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  };

  const handleEditOrder = (orderId) => {
    const order = orders.find((order) => order?.pkorderId === orderId);
    if (order) {
      setSelectedOrder(order);
      setInHouseTrackStatus(order.inHouseTrackStatus);
      setInHouseTrackStatusRemark(order.inHouseTrackStatusRemark);
      setInHouseTrackStatusResult(order.inHouseTrackStatusResult);
      setError("");
    }
  };

  const handleViewOrder = (orderId) => {
    const order = orders.find((order) => order?.pkorderId === orderId);
    if (order) {
      setSelectedOrder(order);
    }
  };

  const columns = [
    {
      name: "Order ID",
      selector: (row) => row?.pkorderId,
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row?.clientName,
      sortable: true,
    },
    {
      name: "Client Email",
      selector: (row) => row?.clientEmail,
    },
    {
      name: "Client Phone",
      selector: (row) => row?.clientPhNo1,
    },
    {
      name: "Quantity",
      selector: (row) => calculateTotalQuantity(row),
    },
    {
      name: "Order Status",
      selector: (row) => row?.orderStatus,
      sortable: true,
    },
    {
      name: "Order Amount",
      selector: (row) => row?.orderAmount,
    },
    {
      name: "Order ID",
      // selector: (row) => row?.orderId,
      cell: (row) => (
        <div className="order-id-cell" title={row?.orderId}>
          {row?.orderId}
        </div>
      ),
    },
    {
      name: "Order In House Track Status",
      selector: (row) => row?.inHouseTrackStatus,
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => dayjs(row?.orderDate).format("YYYY-MM-DD"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            type="button"
            className="order-edi-btn me-2"
            data-bs-toggle="modal"
            data-bs-target="#editOrderModal"
            onClick={() => handleEditOrder(row?.pkorderId)}
          >
            <MdEdit className="order-view-svg-btn" />
          </button>
          <button
            type="button"
            className="order-view-btn"
            data-bs-toggle="modal"
            data-bs-target="#orderViewModal"
            onClick={() => handleViewOrder(row?.pkorderId)}
          >
            <FaEye className="order-view-svg-btn" />
          </button>
        </>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
      },
    },
  };

  if (loading) {
    return (
      <div className="loading-data">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <section className="order-page">
        <div className="our-container px-3">
          <div className="inner-container">
            <div className="order-heading mb-4">
              <h2 className="m-0">Orders</h2>
              {filteredOrders.length > 0 && (
                <p className="total-sale">Total Sale : {totalSale}</p>
              )}
            </div>
            <div className="order-page-heading mb-3">
              <div className="product-orders-filter-block-row">
                <input
                  type="text"
                  placeholder="Search by Order ID"
                  value={orderIdFilter}
                  onChange={(e) => setOrderIdFilter(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Search by Client Email"
                  value={emailFilter}
                  onChange={(e) => setEmailFilter(e.target.value)}
                />
                <select
                  className="form-select"
                  aria-label="Status select"
                  value={orderStatusFilter}
                  onChange={(e) => setOrderStatusFilter(e.target.value)}
                >
                  <option value="">Order Status</option>
                  {orderStatusList.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select"
                  aria-label="In House Status"
                  value={inHouseTrackStatusFilter}
                  onChange={(e) => setInHouseTrackStatusFilter(e.target.value)}
                >
                  <option value="">In House Status</option>
                  <option value="New Order">New Order</option>
                  <option value="Quality Check">Quality Check</option>
                  <option value="Labeling Process">Labeling Process</option>
                  <option value="Order Packing">Order Packing</option>
                  <option value="Billing">Billing</option>
                </select>
                <div className="start-date-block">
                  <DatePicker
                    className="start-date"
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date ? dayjs(date) : null);
                    }}
                    placeholderText="Start Date"
                  />
                </div>
                <div className="end-date-block">
                  <DatePicker
                    className="end-date"
                    value={endDate}
                    onChange={(date) => {
                      setEndDate(date ? dayjs(date) : null);
                    }}
                    placeholderText="End Date"
                  />
                </div>
              </div>
            </div>
            <div className="orders-body">
              {loading ? (
                <p className="error-show-para">Loading...</p>
              ) : filteredOrders.length === 0 ? (
                <div className="error-show-para">
                  <p>No orders found</p>
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={filteredOrders}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  responsive
                  paginationPerPage={7}
                  paginationRowsPerPageOptions={[7, 10, 20]}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="editOrderModal"
        tabIndex="-1"
        aria-labelledby="editOrderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editOrderModalLabel">
                Order Update
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body edit-order-body">
              <form>
                <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                  <label htmlFor="status" className="text-capitalize mb-1">
                    In-House Track Status
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="inHouseTrackStatus"
                    value={inHouseTrackStatus || null}
                    onChange={(e) => setInHouseTrackStatus(e.target.value)}
                  >
                    <option value="New Order">New Order</option>
                    <option value="Quality Check">Quality Check</option>
                    <option value="Labeling Process">Labeling Process</option>
                    <option value="Order Packing">Order Packing</option>
                    <option value="Billing">Billing</option>
                  </select>
                </div>
                {inHouseTrackStatus !== "Billing" && (
                  <>
                    <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                      <label htmlFor="agree" className="text-capitalize mb-1">
                        In-House Track Status Result
                      </label>
                      <div className="form-check d-flex align-items-center gap-2">
                        <input
                          className="form-check-input form-check-input-btn"
                          type="radio"
                          id="approved"
                          name="approval"
                          value="Approved"
                          checked={
                            inHouseTrackStatusResult === "Approved" || null
                          }
                          onChange={(e) =>
                            setInHouseTrackStatusResult(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label mt-2 mb-0"
                          htmlFor="approved"
                        >
                          Approved
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center gap-2">
                        <input
                          className="form-check-input form-check-input-btn"
                          type="radio"
                          id="rejected"
                          name="approval"
                          value="Rejected"
                          checked={
                            inHouseTrackStatusResult === "Rejected" || null
                          }
                          onChange={(e) =>
                            setInHouseTrackStatusResult(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label mt-2 mb-0"
                          htmlFor="rejected"
                        >
                          Failed
                        </label>
                      </div>
                      {error && (
                        <p className="error-message" style={{ color: "red" }}>
                          {error}
                        </p>
                      )}
                    </div>
                  </>
                )}
                <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                  <label htmlFor="message" className="text-capitalize mb-1">
                    Remarks
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows={"5"}
                    autoComplete="off"
                    className="p-2 rounded-0"
                    value={inHouseTrackStatusRemark || null}
                    onChange={(e) =>
                      setInHouseTrackStatusRemark(e.target.value)
                    }
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="text-capitalize contact-form-button my-btn"
                  onClick={(e) => handleUpdateStatus(e)}
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="orderViewModal"
        tabIndex="-1"
        aria-labelledby="orderViewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="orderViewModalLabel">
                Order View
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="orderViewModal"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {selectedOrder ? (
                  <>
                    <div
                      className="row mb-2"
                      style={{ borderBottom: "1px solid rgb(0 0 34 / 14%)" }}
                    >
                      <div className="com-12 col-sm-6 col-md-6 col-lg-6">
                        <p className="mb-1">
                          <strong>Client Name :</strong>{" "}
                          {selectedOrder?.clientName}
                        </p>
                        <p className="mb-1">
                          <strong>Email :</strong> {selectedOrder?.clientEmail}
                        </p>
                        <p className="mb-1">
                          <strong>Phone :</strong>{" "}
                          {selectedOrder?.clientPhNo1 ||
                            selectedOrder?.clientPhNo2 ||
                            "N/A"}
                        </p>
                        <p className="mb-1">
                          <strong>Address 1 :</strong>{" "}
                          {selectedOrder?.clientAdd1 || "N/A"}
                        </p>
                        <p className="mb-1">
                          <strong>Address 2 :</strong>{" "}
                          {selectedOrder?.clientAdd2 || "N/A"}
                        </p>
                      </div>
                      <div className="com-12 col-sm-6 col-md-6 col-lg-6">
                        <p className="mb-1">
                          <strong>Order Status :</strong>{" "}
                          {selectedOrder?.orderStatus}
                        </p>
                        <p className="mb-1">
                          <strong>Order Amount :</strong>{" "}
                          {selectedOrder?.orderAmount}
                        </p>
                        <p className="mb-1">
                          <strong>Order ID :</strong> {selectedOrder?.orderId}
                        </p>
                        <p className="mb-1">
                          <strong>Payment ID :</strong>{" "}
                          {selectedOrder?.paymentId || "N/A"}
                        </p>
                      </div>
                    </div>

                    {/* Suborder Details */}
                    <div className="">
                      <div>
                        {selectedOrder?.orderDetail &&
                        selectedOrder?.orderDetail.length > 0 ? (
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Product Category</th>
                                  <th>Product Price</th>
                                  <th>Product Code</th>
                                  <th>Product Name</th>
                                  <th>Quantity</th>
                                  <th>Images</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedOrder?.orderDetail.map((detail) => {
                                  return detail?.subOrderDetail &&
                                    detail?.subOrderDetail.length > 0 ? (
                                    detail?.subOrderDetail.map(
                                      (subDetail, index) => (
                                        <tr key={index}>
                                          {index === 0 && (
                                            <>
                                              <td
                                                rowSpan={
                                                  detail?.subOrderDetail.length
                                                }
                                              >
                                                {detail?.prodCategory}
                                              </td>
                                              <td
                                                rowSpan={
                                                  detail?.subOrderDetail.length
                                                }
                                              >
                                                {detail?.prodPrice}
                                              </td>
                                            </>
                                          )}
                                          <td>
                                            {detail?.prodCode.split("_")[0] ||
                                              ""}
                                          </td>
                                          <td>{subDetail?.productName}</td>
                                          <td>{subDetail?.quantity}</td>
                                          <td>
                                            <img
                                              src={subDetail.imagePath[0]}
                                              alt="suborder-img"
                                              style={{
                                                width: "60px",
                                                margin: "5px",
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={7}>
                                        No suborders available.
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <p>No order details available.</p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <p>No order selected.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { FaEye } from "react-icons/fa";
// import { MdEdit } from "react-icons/md";

// const Orders = () => {
//   const [orderStatusList, setOrderStatusList] = useState([]);
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(5);
//   const [totalPages, setTotalPages] = useState(0);
//   const [searchParams, setSearchParams] = useState({
//     orderId: "",
//     paymentId: "",
//     clientEmail: "",
//     orderStatus: "",
//   });
//   // const [isSearchClicked, setIsSearchClicked] = useState(false);
//   const [selectedOrder, setSelectedOrder] = useState(null);

//   useEffect(() => {
//     const fetchOrderStatusList = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/order/orderStatusList`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );
//         if (response.ok) {
//           const data = await response.json();
//           setOrderStatusList(data);
//         } else {
//           console.error("Failed to fetch order status list");
//         }
//       } catch (error) {
//         console.error("Error:", error);
//       }
//     };

//     fetchOrderStatusList();
//   }, []);

//   useEffect(() => {
//     // if (isSearchClicked) {
//     fetchOrders();
//     // }
//   }, [currentPage, pageSize]);

//   const fetchOrders = async (isSearch = false) => {
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/order/search`,
//         {
//           // pkOrdID: "",
//           // clientName: "",
//           // clientEmail: searchParams.clientEmail,
//           // clientPhNo: "",
//           // clientAdd: "",
//           // orderStatus: searchParams.orderStatus,
//           // orderId: searchParams.orderId,
//           // paymentId: searchParams.paymentId,
//           // pageSize: pageSize,
//           // pageNo: currentPage,
//           pkOrdID: "",
//           clientName: "",
//           clientEmail: isSearch ? searchParams.clientEmail : "",
//           clientPhNo: "",
//           clientAdd: "",
//           orderStatus: isSearch ? searchParams.orderStatus : "",
//           orderId: isSearch ? searchParams.orderId : "",
//           paymentId: isSearch ? searchParams.paymentId : "",
//           pageSize: pageSize,
//           pageNo: currentPage,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );

//       if (response.data.status === "Successful") {
//         setOrders(response.data.orderData);
//         setTotalPages(Math.ceil(response.data.totalResult / pageSize));
//         setError(null);
//       } else {
//         setOrders([]);
//         setTotalPages(0);
//         setError("No orders found");
//       }
//     } catch (error) {
//       setError("Error fetching orders");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   const handleSearchChange = (e) => {
//     const { name, value } = e.target;
//     setSearchParams((prevParams) => ({
//       ...prevParams,
//       [name]: value,
//     }));
//   };

//   const handleSearchSubmit = (e) => {
//     e.preventDefault();
//     // setIsSearchClicked(true);
//     setCurrentPage(1); // Reset to first page on search
//     fetchOrders(true);
//   };

//   const handleViewOrder = (orderId) => {
//     const order = orders.find((order) => order.pkorderId === orderId);
//     if (order) {
//       setSelectedOrder(order);
//     }
//   };

//   if (loading) {
//     return (
//       <div className="loading-data">
//         <p>Loading...</p>
//       </div>
//     );
//   }

//   return (
//     <>
//       <section className="order-page">
//         <div className="our-container px-3">
//           <div className="inner-container">
//             <div className="order-heading mb-4">
//               <h2>Orders</h2>
//             </div>
//             <div className="order-page-heading mb-4 d-flex align-items-center justify-content-end mx-3">
//               <form onSubmit={handleSearchSubmit}>
//                 <div className="grid-block">
//                   <div className="grid-b-item">
//                     <input
//                       type="text"
//                       placeholder="Order Id"
//                       name="orderId"
//                       value={searchParams.orderId}
//                       onChange={handleSearchChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="grid-b-item">
//                     <input
//                       type="text"
//                       placeholder="Payment Id"
//                       name="paymentId"
//                       value={searchParams.paymentId}
//                       onChange={handleSearchChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="grid-b-item">
//                     <input
//                       type="text"
//                       placeholder="Client Email"
//                       name="clientEmail"
//                       value={searchParams.clientEmail}
//                       onChange={handleSearchChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="grid-b-item">
//                     <select
//                       name="orderStatus"
//                       value={searchParams.orderStatus}
//                       onChange={handleSearchChange}
//                       autoComplete="off"
//                     >
//                       <option value="">Select Status</option>
//                       {orderStatusList.option &&
//                         orderStatusList.option.map((status) => (
//                           <option key={status} value={status}>
//                             {status}
//                           </option>
//                         ))}
//                     </select>
//                   </div>
//                 </div>
//                 <button
//                   type="submit"
//                   className="btn btn-primary order-page-btn fs-6"
//                 >
//                   Search
//                 </button>
//               </form>
//             </div>
//             {/* Display orders or no orders found */}
//             {orders.length > 0 ? (
//               <div className="order-page-body table-responsive">
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th scope="col">#</th>
//                       <th scope="col">Client Name</th>
//                       <th scope="col">Email</th>
//                       <th scope="col">Phone</th>
//                       <th scope="col">Address</th>
//                       <th scope="col">Quantity</th>
//                       <th scope="col">Order Status</th>
//                       <th scope="col">Order Amount</th>
//                       <th scope="col">Order ID</th>
//                       <th scope="col">Payment ID</th>
//                       <th scope="col">Action</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {orders.map((order, index) => {
//                       // Calculate the total quantity from subOrderDetail
//                       const totalQuantity = order.orderDetail.reduce(
//                         (sum, detail) => {
//                           return (
//                             sum +
//                             detail.subOrderDetail.reduce(
//                               (subSum, subDetail) =>
//                                 subSum + subDetail.quantity,
//                               0
//                             )
//                           );
//                         },
//                         0
//                       );

//                       return (
//                         <tr key={order.pkorderId}>
//                           <th scope="row">
//                             {index + 1 + (currentPage - 1) * pageSize}
//                           </th>
//                           <td>{order.clientName}</td>
//                           <td>{order.clientEmail}</td>
//                           <td>{order.clientPhNo1 || order.clientPhNo2}</td>
//                           <td>{order.clientAdd1 || order.clientAdd2}</td>
//                           <td>{totalQuantity}</td>
//                           <td>{order.orderStatus}</td>
//                           <td>{order.orderAmount}</td>
//                           <td>{order.orderId}</td>
//                           <td>{order.paymentId || "N/A"}</td>
//                           <td>
//                             {/* <button
//                               type="button"
//                               className="edit-order-btn me-2"
//                               data-bs-toggle="modal"
//                               data-bs-target="#editOrderModal"
//                             >
//                               <MdEdit />
//                             </button> */}
//                             <button
//                               type="button"
//                               className="view-order-btn"
//                               data-bs-toggle="modal"
//                               data-bs-target="#viewOrderModal"
//                               onClick={() => handleViewOrder(order.pkorderId)}
//                             >
//                               <FaEye />
//                             </button>
//                           </td>
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                 </table>
//                 <div className="pagination-controls">
//                   <label>
//                     Show
//                     <select
//                       value={pageSize}
//                       onChange={(e) => setPageSize(Number(e.target.value))}
//                     >
//                       <option value={5}>5</option>
//                       <option value={10}>10</option>
//                       <option value={15}>15</option>
//                     </select>
//                     Orders per Page
//                   </label>
//                   <div className="pagination-buttons">
//                     <button
//                       className="pagination-btn"
//                       onClick={() => handlePageChange(currentPage - 1)}
//                       disabled={currentPage === 1}
//                     >
//                       Previous
//                     </button>
//                     <span>
//                       Page {currentPage} of {totalPages}
//                     </span>
//                     <button
//                       className="pagination-btn"
//                       onClick={() => handlePageChange(currentPage + 1)}
//                       disabled={currentPage >= totalPages}
//                     >
//                       Next
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               <div className="loading-data">
//                 <p>No Orders found.</p>
//               </div>
//             )}
//           </div>
//         </div>
//       </section>

//       <div
//         className="modal fade"
//         id="editOrderModal"
//         tabIndex="-1"
//         aria-labelledby="editOrderModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="editOrderModalLabel">
//                 Order Update
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body edit-order-body">
//               <form>
//                 <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
//                   <label htmlFor="status" className="text-capitalize mb-1">
//                     Status
//                     {/* <span className="text-danger">*</span> */}
//                   </label>
//                   <select
//                     class="form-select"
//                     aria-label="Default select example"
//                   >
//                     <option value="New Order">New Order</option>
//                     <option value="Quality Check">Quality Check</option>
//                     <option value="Labeling Process">Labeling Process</option>
//                     <option value="Order Packing">Order Packing</option>
//                     <option value="Billing ">Billing </option>
//                   </select>
//                 </div>
//                 <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
//                   <label htmlFor="agree" className="text-capitalize mb-1">
//                     Agree
//                     {/* <span className="text-danger">*</span> */}
//                   </label>
//                   <div class="form-check d-flex align-items-center gap-2">
//                     <input
//                       class="form-check-input form-check-input-btn"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="Approved"
//                     />
//                     <label class="form-check-label mt-2 mb-0" htmlFor="Approved">
//                       Approved
//                     </label>
//                   </div>
//                   <div class="form-check d-flex align-items-center gap-2">
//                     <input
//                       class="form-check-input form-check-input-btn"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="Failed"
//                     />
//                     <label class="form-check-label mt-2 mb-0" htmlFor="Failed">
//                       Failed
//                     </label>
//                   </div>
//                 </div>
//                 <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
//                   <label htmlFor="message" className="text-capitalize mb-1">
//                     Remarkable
//                   </label>
//                   <textarea
//                     name="message"
//                     id="message"
//                     rows={"5"}
//                     autoComplete="off"
//                     className="p-2 rounded-0"
//                   ></textarea>
//                 </div>
//                 <button
//                   type="submit"
//                   className="text-capitalize contact-form-button"
//                 >
//                   Send
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         className="modal fade"
//         id="viewOrderModal"
//         tabIndex="-1"
//         aria-labelledby="viewOrderModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered modal-xl">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="viewOrderModalLabel">
//                 Order Detail
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <div>
//                 {selectedOrder ? (
//                   <>
//                     <div
//                       className="row mb-2"
//                       style={{ borderBottom: "1px solid rgb(0 0 34 / 14%)" }}
//                     >
//                       <div className="com-12 col-sm-6 col-md-6 col-lg-6">
//                         <p className="mb-1">
//                           <strong>Client Name :</strong>{" "}
//                           {selectedOrder.clientName}
//                         </p>
//                         <p className="mb-1">
//                           <strong>Email :</strong> {selectedOrder.clientEmail}
//                         </p>
//                         <p className="mb-1">
//                           <strong>Phone :</strong>{" "}
//                           {selectedOrder.clientPhNo1 ||
//                             selectedOrder.clientPhNo2 ||
//                             "N/A"}
//                         </p>
//                         <p className="mb-1">
//                           <strong>Address 1 :</strong>{" "}
//                           {selectedOrder.clientAdd1 || "N/A"}
//                         </p>
//                       </div>
//                       <div className="com-12 col-sm-6 col-md-6 col-lg-6">
//                         <p className="mb-1">
//                           <strong>Order Status :</strong>{" "}
//                           {selectedOrder.orderStatus}
//                         </p>
//                         <p className="mb-1">
//                           <strong>Order Amount :</strong>{" "}
//                           {selectedOrder.orderAmount}
//                         </p>
//                         <p className="mb-1">
//                           <strong>Order ID :</strong> {selectedOrder.orderId}
//                         </p>
//                         <p className="mb-1">
//                           <strong>Payment ID :</strong>{" "}
//                           {selectedOrder.paymentId || "N/A"}
//                         </p>
//                       </div>
//                     </div>

//                     {/* Suborder Details */}
//                     <div className="">
//                       <div>
//                         {selectedOrder.orderDetail &&
//                         selectedOrder.orderDetail.length > 0 ? (
//                           <div className="table-responsive">
//                             <table className="table table-bordered">
//                               <thead>
//                                 <tr>
//                                   <th>Product Category</th>
//                                   <th>Product Price</th>
//                                   <th>Product Code</th>
//                                   <th>Product Name</th>
//                                   <th>Custom Message</th>
//                                   <th>Zodiac Sign</th>
//                                   <th>Image Color</th>
//                                   <th>Quantity</th>
//                                   <th>Images</th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {selectedOrder.orderDetail.map((detail) => {
//                                   // Assuming there could be multiple subOrderDetails
//                                   return detail.subOrderDetail &&
//                                     detail.subOrderDetail.length > 0 ? (
//                                     detail.subOrderDetail.map(
//                                       (subDetail, index) => (
//                                         <tr key={index}>
//                                           {/* Displaying main order details only once */}
//                                           {index === 0 && (
//                                             <>
//                                               <td
//                                                 rowSpan={
//                                                   detail.subOrderDetail.length
//                                                 }
//                                               >
//                                                 {detail.prodCategory}
//                                               </td>
//                                               <td
//                                                 rowSpan={
//                                                   detail.subOrderDetail.length
//                                                 }
//                                               >
//                                                 {detail.prodPrice}
//                                               </td>
//                                               <td
//                                                 rowSpan={
//                                                   detail.subOrderDetail.length
//                                                 }
//                                               >
//                                                 {detail.prodCode}
//                                               </td>
//                                             </>
//                                           )}
//                                           {/* Displaying suborder details */}
//                                           <td>{subDetail.productName}</td>
//                                           <td>
//                                             {subDetail.customMessage || "-"}
//                                           </td>
//                                           <td>{subDetail.zodiacSign || "-"}</td>
//                                           <td>{subDetail.imageColor}</td>
//                                           <td>{subDetail.quantity}</td>
//                                           <td>
//                                             <img
//                                               src={subDetail.imagePath[0]}
//                                               alt="suborder-img"
//                                               style={{
//                                                 width: "60px",
//                                                 margin: "5px",
//                                               }}
//                                             />
//                                           </td>
//                                         </tr>
//                                       )
//                                     )
//                                   ) : (
//                                     <tr>
//                                       <td colSpan={7}>
//                                         No suborders available.
//                                       </td>
//                                     </tr>
//                                   );
//                                 })}
//                               </tbody>
//                             </table>
//                           </div>
//                         ) : (
//                           <p>No order details available.</p>
//                         )}
//                       </div>
//                     </div>
//                   </>
//                 ) : (
//                   <p>No order selected.</p>
//                 )}
//               </div>
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn btn-secondary"
//                 data-bs-dismiss="modal"
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Orders;

// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaEye } from "react-icons/fa";
// // import { MdEdit } from "react-icons/md";

// // const Orders = () => {
// //   const [orderStatusList, setOrderStatusList] = useState([]);
// //   const [orders, setOrders] = useState([]);
// //   const [loading, setLoading] = useState(false);
// //   const [error, setError] = useState(null);
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [pageSize, setPageSize] = useState(5);
// //   const [totalPages, setTotalPages] = useState(0);
// //   const [searchParams, setSearchParams] = useState({
// //     orderId: "",
// //     paymentId: "",
// //     clientEmail: "",
// //     orderStatus: "",
// //   });
// //   // const [isSearchClicked, setIsSearchClicked] = useState(false);
// //   const [selectedOrder, setSelectedOrder] = useState(null);

// //   useEffect(() => {
// //     const fetchOrderStatusList = async () => {
// //       try {
// //         const response = await fetch(
// //           `${process.env.REACT_APP_API_URL}/order/orderStatusList`,
// //           {
// //             method: "GET",
// //             headers: {
// //               Authorization: `Bearer ${localStorage.getItem("token")}`,
// //             },
// //           }
// //         );
// //         if (response.ok) {
// //           const data = await response.json();
// //           setOrderStatusList(data);
// //         } else {
// //           console.error("Failed to fetch order status list");
// //         }
// //       } catch (error) {
// //         console.error("Error:", error);
// //       }
// //     };

// //     fetchOrderStatusList();
// //   }, []);

// //   useEffect(() => {
// //     // if (isSearchClicked) {
// //     fetchOrders();
// //     // }
// //   }, [currentPage, pageSize]);

// //   const fetchOrders = async (isSearch = false) => {
// //     setLoading(true);
// //     try {
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_API_URL}/order/search`,
// //         {
// //           // pkOrdID: "",
// //           // clientName: "",
// //           // clientEmail: searchParams.clientEmail,
// //           // clientPhNo: "",
// //           // clientAdd: "",
// //           // orderStatus: searchParams.orderStatus,
// //           // orderId: searchParams.orderId,
// //           // paymentId: searchParams.paymentId,
// //           // pageSize: pageSize,
// //           // pageNo: currentPage,
// //           pkOrdID: "",
// //           clientName: "",
// //           clientEmail: isSearch ? searchParams.clientEmail : "",
// //           clientPhNo: "",
// //           clientAdd: "",
// //           orderStatus: isSearch ? searchParams.orderStatus : "",
// //           orderId: isSearch ? searchParams.orderId : "",
// //           paymentId: isSearch ? searchParams.paymentId : "",
// //           pageSize: pageSize,
// //           pageNo: currentPage,
// //         },
// //         {
// //           headers: {
// //             Authorization: `Bearer ${localStorage.getItem("token")}`,
// //           },
// //         }
// //       );

// //       if (response.data.status === "Successful") {
// //         setOrders(response.data.orderData);
// //         setTotalPages(Math.ceil(response.data.totalResult / pageSize));
// //         setError(null);
// //       } else {
// //         setOrders([]);
// //         setTotalPages(0);
// //         setError("No orders found");
// //       }
// //     } catch (error) {
// //       setError("Error fetching orders");
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   const handlePageChange = (newPage) => {
// //     setCurrentPage(newPage);
// //   };

// //   const handleSearchChange = (e) => {
// //     const { name, value } = e.target;
// //     setSearchParams((prevParams) => ({
// //       ...prevParams,
// //       [name]: value,
// //     }));
// //   };

// //   const handleSearchSubmit = (e) => {
// //     e.preventDefault();
// //     // setIsSearchClicked(true);
// //     setCurrentPage(1); // Reset to first page on search
// //     fetchOrders(true);
// //   };

// //   const handleViewOrder = (orderId) => {
// //     const order = orders.find((order) => order.pkorderId === orderId);
// //     if (order) {
// //       setSelectedOrder(order);
// //     }
// //   };

// //   if (loading) {
// //     return (
// //       <div className="loading-data">
// //         <p>Loading...</p>
// //       </div>
// //     );
// //   }

// //   return (
// //     <>
// //       <section className="order-page">
// //         <div className="our-container px-3">
// //           <div className="inner-container">
// //             <div className="order-heading mb-4">
// //               <h2>Orders</h2>
// //             </div>

// //             {/* <div className="order-heading mb-4 d-flex align-items-center justify-content-between">
// //               <div className="">
// //                 <h2>Orders</h2>
// //               </div>
// //               <div className="order-heading d-flex align-items-center justify-content-center">
// //                 <form action="">
// //                   <div className="search-box-input">
// //                     <input type="text" placeholder="Search..." />
// //                   </div>
// //                 </form>
// //               </div>
// //             </div> */}

// //             <div className="order-page-heading mb-4 d-flex align-items-center justify-content-end mx-3">
// //               <form onSubmit={handleSearchSubmit}>
// //                 <div className="grid-block">
// //                   <div className="grid-b-item">
// //                     <input
// //                       type="text"
// //                       placeholder="Order Id"
// //                       name="orderId"
// //                       value={searchParams.orderId}
// //                       onChange={handleSearchChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="grid-b-item">
// //                     <input
// //                       type="text"
// //                       placeholder="Payment Id"
// //                       name="paymentId"
// //                       value={searchParams.paymentId}
// //                       onChange={handleSearchChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="grid-b-item">
// //                     <input
// //                       type="text"
// //                       placeholder="Client Email"
// //                       name="clientEmail"
// //                       value={searchParams.clientEmail}
// //                       onChange={handleSearchChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="grid-b-item">
// //                     <select
// //                       name="orderStatus"
// //                       value={searchParams.orderStatus}
// //                       onChange={handleSearchChange}
// //                       autoComplete="off"
// //                     >
// //                       <option value="">Select Status</option>
// //                       {orderStatusList.option &&
// //                         orderStatusList.option.map((status) => (
// //                           <option key={status} value={status}>
// //                             {status}
// //                           </option>
// //                         ))}
// //                     </select>
// //                   </div>
// //                 </div>
// //                 <button
// //                   type="submit"
// //                   className="btn btn-primary order-page-btn fs-6"
// //                 >
// //                   Search
// //                 </button>
// //               </form>
// //             </div>
// //             {/* Display orders or no orders found */}
// //             {orders.length > 0 ? (
// //               <div className="order-page-body table-responsive">
// //                 <table className="table">
// //                   <thead>
// //                     <tr>
// //                       <th scope="col">#</th>
// //                       <th scope="col">Client Name</th>
// //                       <th scope="col">Email</th>
// //                       <th scope="col">Phone</th>
// //                       <th scope="col">Address</th>
// //                       <th scope="col">Quantity</th>
// //                       <th scope="col">Order Status</th>
// //                       <th scope="col">Order Amount</th>
// //                       <th scope="col">Order ID</th>
// //                       <th scope="col">Payment ID</th>
// //                       <th scope="col">Action</th>
// //                     </tr>
// //                   </thead>
// //                   <tbody>
// //                     {orders.map((order, index) => {
// //                       // Calculate the total quantity from subOrderDetail
// //                       const totalQuantity = order.orderDetail.reduce(
// //                         (sum, detail) => {
// //                           return (
// //                             sum +
// //                             detail.subOrderDetail.reduce(
// //                               (subSum, subDetail) =>
// //                                 subSum + subDetail.quantity,
// //                               0
// //                             )
// //                           );
// //                         },
// //                         0
// //                       );

// //                       return (
// //                         <tr key={order.pkorderId}>
// //                           <th scope="row">
// //                             {index + 1 + (currentPage - 1) * pageSize}
// //                           </th>
// //                           <td>{order.clientName}</td>
// //                           <td>{order.clientEmail}</td>
// //                           <td>{order.clientPhNo1 || order.clientPhNo2}</td>
// //                           <td>{order.clientAdd1 || order.clientAdd2}</td>
// //                           <td>{totalQuantity}</td>
// //                           <td>{order.orderStatus}</td>
// //                           <td>{order.orderAmount}</td>
// //                           <td>{order.orderId}</td>
// //                           <td>{order.paymentId || "N/A"}</td>
// //                           <td>
// //                             <button
// //                               type="button"
// //                               className="view-order-btn me-2"
// //                               data-bs-toggle="modal"
// //                               data-bs-target="#viewOrderModal"
// //                             >
// //                               <MdEdit />
// //                             </button>
// //                             <button
// //                               type="button"
// //                               className="view-order-btn"
// //                               data-bs-toggle="modal"
// //                               data-bs-target="#viewOrderModal"
// //                               onClick={() => handleViewOrder(order.pkorderId)}
// //                             >
// //                               <FaEye />
// //                             </button>
// //                           </td>
// //                         </tr>
// //                       );
// //                     })}
// //                     {/* {orders.map((order, index) => (
// //                       <tr key={order.pkorderId}>
// //                         <th scope="row">
// //                           {index + 1 + (currentPage - 1) * pageSize}
// //                         </th>
// //                         <td>{order.clientName}</td>
// //                         <td>{order.clientEmail}</td>
// //                         <td>{order.clientPhNo1 || order.clientPhNo2}</td>
// //                         <td>{order.clientAdd1 || order.clientAdd2}</td>
// //                         <td>quantity</td>
// //                         <td>{order.orderStatus}</td>
// //                         <td>{order.orderAmount}</td>
// //                         <td>{order.orderId}</td>
// //                         <td>{order.paymentId || "N/A"}</td>
// //                         <td>
// //                           <button
// //                             type="button"
// //                             className="view-order-btn"
// //                             data-bs-toggle="modal"
// //                             data-bs-target="#viewOrderModal"
// //                             onClick={() => handleViewOrder(order.pkorderId)}
// //                           >
// //                             <FaEye />
// //                           </button>
// //                         </td>
// //                       </tr>
// //                     ))} */}
// //                   </tbody>
// //                 </table>
// //                 <div className="pagination-controls">
// //                   <label>
// //                     Show
// //                     <select
// //                       value={pageSize}
// //                       onChange={(e) => setPageSize(Number(e.target.value))}
// //                     >
// //                       <option value={5}>5</option>
// //                       <option value={10}>10</option>
// //                       <option value={15}>15</option>
// //                     </select>
// //                     Orders per Page
// //                   </label>
// //                   <div className="pagination-buttons">
// //                     <button
// //                       className="pagination-btn"
// //                       onClick={() => handlePageChange(currentPage - 1)}
// //                       disabled={currentPage === 1}
// //                     >
// //                       Previous
// //                     </button>
// //                     <span>
// //                       Page {currentPage} of {totalPages}
// //                     </span>
// //                     <button
// //                       className="pagination-btn"
// //                       onClick={() => handlePageChange(currentPage + 1)}
// //                       disabled={currentPage >= totalPages}
// //                     >
// //                       Next
// //                     </button>
// //                   </div>
// //                 </div>
// //               </div>
// //             ) : (
// //               <div className="loading-data">
// //                 <p>No Orders found.</p>
// //               </div>
// //             )}
// //             {/* {isSearchClicked ? (
// //               orders.length > 0 ? (
// //                 <div className="order-page-body table-responsive">
// //                   <table className="table">
// //                     <thead>
// //                       <tr>
// //                         <th scope="col">#</th>
// //                         <th scope="col">Client Name</th>
// //                         <th scope="col">Email</th>
// //                         <th scope="col">Phone</th>
// //                         <th scope="col">Address</th>
// //                         <th scope="col">Order Status</th>
// //                         <th scope="col">Order Amount</th>
// //                         <th scope="col">Order ID</th>
// //                         <th scope="col">Payment ID</th>
// //                         <th scope="col">Action</th>
// //                       </tr>
// //                     </thead>
// //                     <tbody>
// //                       {orders.map((order, index) => (
// //                         <tr key={order.pkorderId}>
// //                           <th scope="row">
// //                             {index + 1 + (currentPage - 1) * pageSize}
// //                           </th>
// //                           <td>{order.clientName}</td>
// //                           <td>{order.clientEmail}</td>
// //                           <td>{order.clientPhNo1 || order.clientPhNo2}</td>
// //                           <td>{order.clientAdd1 || order.clientAdd2}</td>
// //                           <td>{order.orderStatus}</td>
// //                           <td>{order.orderAmount}</td>
// //                           <td>{order.orderId}</td>
// //                           <td>{order.paymentId || "N/A"}</td>
// //                           <td>
// //                             <button
// //                               type="button"
// //                               className="view-order-btn"
// //                               data-bs-toggle="modal"
// //                               data-bs-target="#viewOrderModal"
// //                               onClick={() => handleViewOrder(order.pkorderId)}
// //                             >
// //                               <FaEye />
// //                             </button>
// //                           </td>
// //                         </tr>
// //                       ))}
// //                     </tbody>
// //                   </table>
// //                   <div className="pagination-controls">
// //                     <label>
// //                       Show
// //                       <select
// //                         value={pageSize}
// //                         onChange={(e) => setPageSize(Number(e.target.value))}
// //                       >
// //                         <option value={5}>5</option>
// //                         <option value={10}>10</option>
// //                         <option value={15}>15</option>
// //                       </select>
// //                       Orders per Page
// //                     </label>
// //                     <div className="pagination-buttons">
// //                       <button
// //                         className="pagination-btn"
// //                         onClick={() => handlePageChange(currentPage - 1)}
// //                         disabled={currentPage === 1}
// //                       >
// //                         Previous
// //                       </button>
// //                       <span>
// //                         Page {currentPage} of {totalPages}
// //                       </span>
// //                       <button
// //                         className="pagination-btn"
// //                         onClick={() => handlePageChange(currentPage + 1)}
// //                         disabled={currentPage >= totalPages}
// //                       >
// //                         Next
// //                       </button>
// //                     </div>
// //                   </div>
// //                 </div>
// //               ) : (
// //                 <div className="loading-data">
// //                   <p>No Orders found.</p>
// //                 </div>
// //               )
// //             ) : null} */}
// //             {/* {isSearchClicked && orders.length > 0 ? (
// //               <div className="order-page-body table-responsive">
// //                 <table className="table">
// //                   <thead>
// //                     <tr>
// //                       <th scope="col">#</th>
// //                       <th scope="col">Client Name</th>
// //                       <th scope="col">Email</th>
// //                       <th scope="col">Phone</th>
// //                       <th scope="col">Address</th>
// //                       <th scope="col">Order Status</th>
// //                       <th scope="col">Order Amount</th>
// //                       <th scope="col">Order ID</th>
// //                       <th scope="col">Payment ID</th>
// //                       <th scope="col">Action</th>
// //                     </tr>
// //                   </thead>
// //                   <tbody>
// //                     {orders.map((order, index) => (
// //                       <tr key={order.pkorderId}>
// //                         <th scope="row">
// //                           {index + 1 + (currentPage - 1) * pageSize}
// //                         </th>
// //                         <td>{order.clientName}</td>
// //                         <td>{order.clientEmail}</td>
// //                         <td>{order.clientPhNo1 || order.clientPhNo1}</td>
// //                         <td>{order.clientAdd1 || order.clientAdd2}</td>
// //                         <td>{order.orderStatus}</td>
// //                         <td>{order.orderAmount}</td>
// //                         <td>{order.orderId}</td>
// //                         <td>{order.paymentId || "N/A"}</td>
// //                         <td>
// //                           <button
// //                             type="button"
// //                             className="view-order-btn"
// //                             data-bs-toggle="modal"
// //                             data-bs-target="#viewOrderModal"
// //                             onClick={() => handleViewOrder(order.pkorderId)}
// //                           >
// //                             <FaEye />
// //                           </button>
// //                         </td>
// //                       </tr>
// //                     ))}
// //                   </tbody>
// //                 </table>
// //                 <div className="pagination-controls">
// //                   <label>
// //                     Show
// //                     <select
// //                       value={pageSize}
// //                       onChange={(e) => setPageSize(Number(e.target.value))}
// //                     >
// //                       <option value={5}>5</option>
// //                       <option value={10}>10</option>
// //                       <option value={15}>15</option>
// //                     </select>
// //                     Orders per Page
// //                   </label>
// //                   <div className="pagination-buttons">
// //                     <button
// //                       className="pagination-btn"
// //                       onClick={() => handlePageChange(currentPage - 1)}
// //                       disabled={currentPage === 1}
// //                     >
// //                       Previous
// //                     </button>
// //                     <span>
// //                       Page {currentPage} of {totalPages}
// //                     </span>
// //                     <button
// //                       className="pagination-btn"
// //                       onClick={() => handlePageChange(currentPage + 1)}
// //                       disabled={currentPage >= totalPages}
// //                     >
// //                       Next
// //                     </button>
// //                   </div>
// //                 </div>
// //               </div>
// //             ) : (
// //               <>
// //                 <div className="loading-data">
// //                   <p>No Orders found.</p>
// //                 </div>
// //               </>
// //             )} */}
// //           </div>
// //         </div>
// //       </section>

// //       <div
// //         className="modal fade"
// //         id="viewOrderModal"
// //         tabIndex="-1"
// //         aria-labelledby="viewOrderModalLabel"
// //         aria-hidden="true"
// //       >
// //         <div className="modal-dialog modal-dialog-centered modal-xl">
// //           <div className="modal-content">
// //             <div className="modal-header">
// //               <h1 className="modal-title fs-5" id="viewOrderModalLabel">
// //                 Order Detail
// //               </h1>
// //               <button
// //                 type="button"
// //                 className="btn-close"
// //                 data-bs-dismiss="modal"
// //                 aria-label="Close"
// //               ></button>
// //             </div>
// //             <div className="modal-body">
// //               <div>
// //                 {selectedOrder ? (
// //                   <>
// //                     <div
// //                       className="row mb-2"
// //                       style={{ borderBottom: "1px solid rgb(0 0 34 / 14%)" }}
// //                     >
// //                       <div className="com-12 col-sm-6 col-md-6 col-lg-6">
// //                         <p className="mb-1">
// //                           <strong>Client Name :</strong>{" "}
// //                           {selectedOrder.clientName}
// //                         </p>
// //                         <p className="mb-1">
// //                           <strong>Email :</strong> {selectedOrder.clientEmail}
// //                         </p>
// //                         <p className="mb-1">
// //                           <strong>Phone :</strong>{" "}
// //                           {selectedOrder.clientPhNo1 ||
// //                             selectedOrder.clientPhNo2 ||
// //                             "N/A"}
// //                         </p>
// //                         <p className="mb-1">
// //                           <strong>Address 1 :</strong>{" "}
// //                           {selectedOrder.clientAdd1 || "N/A"}
// //                         </p>
// //                         {/* <p>
// //                           <strong>Address 2 :</strong>{" "}
// //                           {selectedOrder.clientAdd2 || "N/A"}
// //                         </p> */}
// //                       </div>
// //                       <div className="com-12 col-sm-6 col-md-6 col-lg-6">
// //                         <p className="mb-1">
// //                           <strong>Order Status :</strong>{" "}
// //                           {selectedOrder.orderStatus}
// //                         </p>
// //                         <p className="mb-1">
// //                           <strong>Order Amount :</strong>{" "}
// //                           {selectedOrder.orderAmount}
// //                         </p>
// //                         <p className="mb-1">
// //                           <strong>Order ID :</strong> {selectedOrder.orderId}
// //                         </p>
// //                         <p className="mb-1">
// //                           <strong>Payment ID :</strong>{" "}
// //                           {selectedOrder.paymentId || "N/A"}
// //                         </p>
// //                       </div>
// //                     </div>

// //                     {/* Suborder Details */}
// //                     <div className="">
// //                       <div>
// //                         {selectedOrder.orderDetail &&
// //                         selectedOrder.orderDetail.length > 0 ? (
// //                           <div className="table-responsive">
// //                             <table className="table table-bordered">
// //                               <thead>
// //                                 <tr>
// //                                   <th>Product Category</th>
// //                                   <th>Product Price</th>
// //                                   <th>Product Code</th>
// //                                   <th>Product Name</th>
// //                                   <th>Custom Message</th>
// //                                   <th>Zodiac Sign</th>
// //                                   <th>Image Color</th>
// //                                   <th>Quantity</th>
// //                                   <th>Images</th>
// //                                 </tr>
// //                               </thead>
// //                               <tbody>
// //                                 {selectedOrder.orderDetail.map((detail) => {
// //                                   // Assuming there could be multiple subOrderDetails
// //                                   return detail.subOrderDetail &&
// //                                     detail.subOrderDetail.length > 0 ? (
// //                                     detail.subOrderDetail.map(
// //                                       (subDetail, index) => (
// //                                         <tr key={index}>
// //                                           {/* Displaying main order details only once */}
// //                                           {index === 0 && (
// //                                             <>
// //                                               <td
// //                                                 rowSpan={
// //                                                   detail.subOrderDetail.length
// //                                                 }
// //                                               >
// //                                                 {detail.prodCategory}
// //                                               </td>
// //                                               <td
// //                                                 rowSpan={
// //                                                   detail.subOrderDetail.length
// //                                                 }
// //                                               >
// //                                                 {detail.prodPrice}
// //                                               </td>
// //                                               <td
// //                                                 rowSpan={
// //                                                   detail.subOrderDetail.length
// //                                                 }
// //                                               >
// //                                                 {detail.prodCode}
// //                                               </td>
// //                                             </>
// //                                           )}
// //                                           {/* Displaying suborder details */}
// //                                           <td>{subDetail.productName}</td>
// //                                           <td>
// //                                             {subDetail.customMessage || "-"}
// //                                           </td>
// //                                           <td>{subDetail.zodiacSign || "-"}</td>
// //                                           <td>{subDetail.imageColor}</td>
// //                                           <td>{subDetail.quantity}</td>
// //                                           <td>
// //                                             <img
// //                                               src={subDetail.imagePath[0]}
// //                                               alt="suborder-img"
// //                                               style={{
// //                                                 width: "60px",
// //                                                 margin: "5px",
// //                                               }}
// //                                             />
// //                                           </td>
// //                                         </tr>
// //                                       )
// //                                     )
// //                                   ) : (
// //                                     <tr>
// //                                       <td colSpan={7}>
// //                                         No suborders available.
// //                                       </td>
// //                                     </tr>
// //                                   );
// //                                 })}
// //                               </tbody>
// //                             </table>
// //                           </div>
// //                         ) : (
// //                           <p>No order details available.</p>
// //                         )}
// //                       </div>
// //                     </div>
// //                   </>
// //                 ) : (
// //                   <p>No order selected.</p>
// //                 )}
// //               </div>
// //             </div>
// //             <div className="modal-footer">
// //               <button
// //                 type="button"
// //                 className="btn btn-secondary"
// //                 data-bs-dismiss="modal"
// //               >
// //                 Close
// //               </button>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Orders;

// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaEye } from "react-icons/fa";

// // const Orders = () => {
// //   const [orderStatusList, setOrderStatusList] = useState([]);
// //   const [orders, setOrders] = useState([]);
// //   const [loading, setLoading] = useState(false);
// //   const [error, setError] = useState(null);
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [pageSize, setPageSize] = useState(5);
// //   const [totalPages, setTotalPages] = useState(0);
// //   const [searchParams, setSearchParams] = useState({
// //     orderId: "",
// //     paymentId: "",
// //     clientEmail: "",
// //     orderStatus: "",
// //   });
// //   const [isSearchClicked, setIsSearchClicked] = useState(false);
// //   const [selectedOrder, setSelectedOrder] = useState(null);

// //   useEffect(() => {
// //     const fetchOrderStatusList = async () => {
// //       try {
// //         const response = await fetch(
// //           `${process.env.REACT_APP_API_URL}/order/orderStatusList`,
// //           {
// //             method: "GET",
// //             headers: {
// //               Authorization: `Bearer ${localStorage.getItem("token")}`,
// //             },
// //           }
// //         );
// //         if (response.ok) {
// //           const data = await response.json();
// //           setOrderStatusList(data);
// //         } else {
// //           console.error("Failed to fetch order status list");
// //         }
// //       } catch (error) {
// //         console.error("Error:", error);
// //       }
// //     };

// //     fetchOrderStatusList();
// //   }, []);

// //   useEffect(() => {
// //     if (isSearchClicked) {
// //       fetchOrders();
// //     }
// //   }, [currentPage, pageSize]);

// //   const fetchOrders = async () => {
// //     setLoading(true);
// //     try {
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_API_URL}/order/search`,
// //         {
// //           pkOrdID: "",
// //           clientName: "",
// //           clientEmail: searchParams.clientEmail,
// //           clientPhNo: "",
// //           clientAdd: "",
// //           orderStatus: searchParams.orderStatus,
// //           orderId: searchParams.orderId,
// //           paymentId: searchParams.paymentId,
// //           pageSize: pageSize,
// //           pageNo: currentPage,
// //         },
// //         {
// //           headers: {
// //             Authorization: `Bearer ${localStorage.getItem("token")}`,
// //           },
// //         }
// //       );

// //       if (response.data.status === "Successful") {
// //         setOrders(response.data.orderData);
// //         setTotalPages(Math.ceil(response.data.totalResult / pageSize));
// //         setError(null);
// //       } else {
// //         setOrders([]);
// //         setTotalPages(0);
// //         setError("No orders found");
// //       }
// //     } catch (error) {
// //       setError("Error fetching orders");
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   const handlePageChange = (newPage) => {
// //     setCurrentPage(newPage);
// //   };

// //   const handleSearchChange = (e) => {
// //     const { name, value } = e.target;
// //     setSearchParams((prevParams) => ({
// //       ...prevParams,
// //       [name]: value,
// //     }));
// //   };

// //   const handleSearchSubmit = (e) => {
// //     e.preventDefault();
// //     setIsSearchClicked(true);
// //     setCurrentPage(1); // Reset to first page on search
// //     fetchOrders();
// //   };

// //   const handleViewOrder = (orderId) => {
// //     const order = orders.find((order) => order.pkorderId === orderId);
// //     if (order) {
// //       setSelectedOrder(order);
// //     }
// //   };

// //   if (loading) {
// //     return (
// //       <div className="loading-data">
// //         <p>Loading...</p>
// //       </div>
// //     );
// //   }

// //   return (
// //     <>
// //       <section className="order-page">
// //         <div className="our-container px-3">
// //           <div className="inner-container">
// //             <div className="order-heading mb-4">
// //               <h2>Orders</h2>
// //             </div>

// //             {/* <div className="order-heading mb-4 d-flex align-items-center justify-content-between">
// //               <div className="">
// //                 <h2>Orders</h2>
// //               </div>
// //               <div className="order-heading d-flex align-items-center justify-content-center">
// //                 <form action="">
// //                   <div className="search-box-input">
// //                     <input type="text" placeholder="Search..." />
// //                   </div>
// //                 </form>
// //               </div>
// //             </div> */}

// //             <div className="order-page-heading mb-4 d-flex align-items-center justify-content-end mx-3">
// //               <form onSubmit={handleSearchSubmit}>
// //                 <div className="grid-block">
// //                   <div className="grid-b-item">
// //                     <input
// //                       type="text"
// //                       placeholder="Order Id"
// //                       name="orderId"
// //                       value={searchParams.orderId}
// //                       onChange={handleSearchChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="grid-b-item">
// //                     <input
// //                       type="text"
// //                       placeholder="Payment Id"
// //                       name="paymentId"
// //                       value={searchParams.paymentId}
// //                       onChange={handleSearchChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="grid-b-item">
// //                     <input
// //                       type="text"
// //                       placeholder="Client Email"
// //                       name="clientEmail"
// //                       value={searchParams.clientEmail}
// //                       onChange={handleSearchChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="grid-b-item">
// //                     <select
// //                       name="orderStatus"
// //                       value={searchParams.orderStatus}
// //                       onChange={handleSearchChange}
// //                       autoComplete="off"
// //                     >
// //                       <option value="">Select Status</option>
// //                       {orderStatusList.option &&
// //                         orderStatusList.option.map((status) => (
// //                           <option key={status} value={status}>
// //                             {status}
// //                           </option>
// //                         ))}
// //                     </select>
// //                   </div>
// //                 </div>
// //                 <button
// //                   type="submit"
// //                   className="btn btn-primary order-page-btn fs-6"
// //                 >
// //                   Search
// //                 </button>
// //               </form>
// //             </div>
// //             {isSearchClicked && (
// //               <div className="order-page-body table-responsive">
// //                 <table className="table">
// //                   <thead>
// //                     <tr>
// //                       <th scope="col">#</th>
// //                       <th scope="col">Client Name</th>
// //                       <th scope="col">Email</th>
// //                       <th scope="col">Phone</th>
// //                       <th scope="col">Address</th>
// //                       <th scope="col">Order Status</th>
// //                       <th scope="col">Order Amount</th>
// //                       <th scope="col">Order ID</th>
// //                       <th scope="col">Payment ID</th>
// //                       <th scope="col">Action</th>
// //                     </tr>
// //                   </thead>
// //                   <tbody>
// //                     {orders.map((order, index) => (
// //                       <tr key={order.pkorderId}>
// //                         <th scope="row">
// //                           {index + 1 + (currentPage - 1) * pageSize}
// //                         </th>
// //                         <td>{order.clientName}</td>
// //                         <td>{order.clientEmail}</td>
// //                         <td>{order.clientPhNo1 || order.clientPhNo1}</td>
// //                         <td>{order.clientAdd1 || order.clientAdd2}</td>
// //                         <td>{order.orderStatus}</td>
// //                         <td>{order.orderAmount}</td>
// //                         <td>{order.orderId}</td>
// //                         <td>{order.paymentId || "N/A"}</td>
// //                         <td>
// //                           <button
// //                             type="button"
// //                             className="view-order-btn"
// //                             data-bs-toggle="modal"
// //                             data-bs-target="#viewOrderModal"
// //                             onClick={() => handleViewOrder(order.pkorderId)}
// //                           >
// //                             <FaEye />
// //                           </button>
// //                         </td>
// //                       </tr>
// //                     ))}
// //                   </tbody>
// //                 </table>
// //                 <div className="pagination-controls">
// //                   <label>
// //                     Show
// //                     <select
// //                       value={pageSize}
// //                       onChange={(e) => setPageSize(Number(e.target.value))}
// //                     >
// //                       <option value={5}>5</option>
// //                       <option value={10}>10</option>
// //                       <option value={15}>15</option>
// //                     </select>
// //                     Orders per Page
// //                   </label>
// //                   <div className="pagination-buttons">
// //                     <button
// //                       className="pagination-btn"
// //                       onClick={() => handlePageChange(currentPage - 1)}
// //                       disabled={currentPage === 1}
// //                     >
// //                       Previous
// //                     </button>
// //                     <span>
// //                       Page {currentPage} of {totalPages}
// //                     </span>
// //                     <button
// //                       className="pagination-btn"
// //                       onClick={() => handlePageChange(currentPage + 1)}
// //                       disabled={currentPage >= totalPages}
// //                     >
// //                       Next
// //                     </button>
// //                   </div>
// //                 </div>
// //               </div>
// //             )}
// //           </div>
// //         </div>
// //       </section>

// //       <div
// //         className="modal fade"
// //         id="viewOrderModal"
// //         tabIndex="-1"
// //         aria-labelledby="viewOrderModalLabel"
// //         aria-hidden="true"
// //       >
// //         <div className="modal-dialog modal-dialog-centered modal-xl">
// //           <div className="modal-content">
// //             <div className="modal-header">
// //               <h1 className="modal-title fs-5" id="viewOrderModalLabel">
// //                 Order Detail
// //               </h1>
// //               <button
// //                 type="button"
// //                 className="btn-close"
// //                 data-bs-dismiss="modal"
// //                 aria-label="Close"
// //               ></button>
// //             </div>
// //             <div className="modal-body">
// //               <div className="row">
// //                 {selectedOrder ? (
// //                   <>
// //                     <div className="com-12 col-sm-6 col-md-6 col-lg-6">
// //                       <p>
// //                         <strong>Client Name:</strong> {selectedOrder.clientName}
// //                       </p>
// //                       <p>
// //                         <strong>Email:</strong> {selectedOrder.clientEmail}
// //                       </p>
// //                       <p>
// //                         <strong>Phone:</strong>{" "}
// //                         {selectedOrder.clientPhNo1 ||
// //                           selectedOrder.clientPhNo2 ||
// //                           "N/A"}
// //                       </p>
// //                       <p>
// //                         <strong>Address 1:</strong>{" "}
// //                         {selectedOrder.clientAdd1 || "N/A"}
// //                       </p>
// //                       <p>
// //                         <strong>Address 2:</strong>{" "}
// //                         {selectedOrder.clientAdd2 || "N/A"}
// //                       </p>
// //                       <p>
// //                         <strong>Order Status:</strong>{" "}
// //                         {selectedOrder.orderStatus}
// //                       </p>
// //                       <p>
// //                         <strong>Order Amount:</strong>{" "}
// //                         {selectedOrder.orderAmount}
// //                       </p>
// //                       <p>
// //                         <strong>Order ID:</strong> {selectedOrder.orderId}
// //                       </p>
// //                       <p>
// //                         <strong>Payment ID:</strong>{" "}
// //                         {selectedOrder.paymentId || "N/A"}
// //                       </p>
// //                     </div>

// //                     {/* Accordion for Suborder Details */}
// //                     <div className="com-12 col-sm-6 col-md-6 col-lg-6">
// //                       <div className="accordion" id="suborderAccordion">
// //                         {selectedOrder.orderDetail &&
// //                         selectedOrder.orderDetail.length > 0 ? (
// //                           selectedOrder.orderDetail.map((detail) => (
// //                             <div key={detail.pkProdId}>
// //                               <h4>Product Name: {detail.prodName}</h4>
// //                               <p>Product Category: {detail.prodCategory}</p>
// //                               <p>Product Price: {detail.prodPrice}</p>
// //                               <p>Product Code: {detail.prodCode}</p>
// //                               {/* <p>Product Description: {detail.prodDesc}</p> */}

// //                               <h5>Sub Order Details:</h5>
// //                               {detail.subOrderDetail &&
// //                               detail.subOrderDetail.length > 0 ? (
// //                                 detail.subOrderDetail.map(
// //                                   (subDetail, index) => (
// //                                     <div key={index}>
// //                                       <p>
// //                                         Product Name: {subDetail.productName}
// //                                       </p>
// //                                       <p>Image Color: {subDetail.imageColor}</p>
// //                                       <p>Quantity: {subDetail.quantity}</p>
// //                                       <div>
// //                                         {subDetail.imagePath.map((image, i) => (
// //                                           <img
// //                                             key={i}
// //                                             src={image}
// //                                             alt={`Sub Order ${i}`}
// //                                             style={{ width: "60px" }}
// //                                           />
// //                                         ))}
// //                                       </div>
// //                                     </div>
// //                                   )
// //                                 )
// //                               ) : (
// //                                 <p>No suborders available.</p>
// //                               )}
// //                             </div>
// //                           ))
// //                         ) : (
// //                           <p>No order details available.</p>
// //                         )}
// //                       </div>
// //                     </div>
// //                   </>
// //                 ) : (
// //                   <p>No order selected.</p>
// //                 )}
// //                 {/* <div className="com-12 col-sm-6 col-md-6 col-lg-6">
// //                   <div className="mb-3">
// //                     <label htmlFor="clientName" className="form-label">
// //                       Client Name
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="clientName"
// //                       value={selectedOrder?.clientName || ""}
// //                       readOnly
// //                     />
// //                   </div>
// //                   <div className="mb-3">
// //                     <label htmlFor="clientEmail" className="form-label">
// //                       Client Email
// //                     </label>
// //                     <input
// //                       type="email"
// //                       className="form-control"
// //                       id="clientEmail"
// //                       value={selectedOrder?.clientEmail || ""}
// //                       readOnly
// //                     />
// //                   </div>
// //                   <div className="mb-3">
// //                     <label htmlFor="clientPhNo1" className="form-label">
// //                       Client Phone
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="clientPhNo1"
// //                       value={selectedOrder?.clientPhNo1 || ""}
// //                       readOnly
// //                     />
// //                   </div>
// //                   <div className="mb-3">
// //                     <label htmlFor="clientAdd1" className="form-label">
// //                       Address
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="clientAdd1"
// //                       value={selectedOrder?.clientAdd1 || ""}
// //                       readOnly
// //                     />
// //                   </div>
// //                   <div className="mb-3">
// //                     <label htmlFor="city" className="form-label">
// //                       City
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="city"
// //                       value={selectedOrder?.city || ""}
// //                       readOnly
// //                     />
// //                   </div>
// //                   <div className="mb-3">
// //                     <label htmlFor="state" className="form-label">
// //                       State
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="state"
// //                       value={selectedOrder?.state || ""}
// //                       readOnly
// //                     />
// //                   </div>
// //                   <div className="mb-3">
// //                     <label htmlFor="orderAmount" className="form-label">
// //                       Order Amount
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="orderAmount"
// //                       value={selectedOrder?.orderAmount || ""}
// //                       readOnly
// //                     />
// //                   </div>
// //                   <div className="mb-3">
// //                     <label htmlFor="orderStatus" className="form-label">
// //                       Order Status
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="orderStatus"
// //                       value={selectedOrder?.orderStatus || ""}
// //                       readOnly
// //                     />
// //                   </div>
// //                 </div>
// //                 <div className="com-12 col-sm-6 col-md-6 col-lg-6">
// //                   sub order details show
// //                 </div> */}
// //               </div>
// //             </div>
// //             <div className="modal-footer">
// //               <button
// //                 type="button"
// //                 className="btn btn-secondary"
// //                 data-bs-dismiss="modal"
// //               >
// //                 Close
// //               </button>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Orders;
